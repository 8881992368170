<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap">
  <div class="common-text">
    <h2>Select category of your choice.</h2>
   
  </div>
</section>
<section class="categorytile-design">
  <div class="row">
    <div
      *ngIf="categories?.length > 0"
      class="col-xl-12 col-sm-12 col-md-12 col-xs-12 view-right whitebg-new"
    >
      <!-- <h3 class="tf blk">Filter By Tips Categories</h3> -->
      <div class="title">
        <div class="title-wrap">
          Filter By Tips Categories
        </div>
      </div>
      <div *ngFor="let category of categories" class="checkbox-wrap">
        <div class="checkbox-child">
          <label>
            <input
              (click)="categoryClicked(category)"
              #saveUsername
              [(ngModel)]="category.isChecked"
              type="checkbox"
              data-toggle="toggle"
              [ngModelOptions]="{ standalone: true }"
            />
            <span>{{ category.CategoryName | titlecase }}</span>
            <!-- <span> ({{ category.TotalTips }})</span> -->
            <div class="checkbox"></div>
          </label>
        </div>
      </div>
      <div class="load-more">
        <button *ngIf="!noMoreRecordsAvailable" (click)="loadMoreTips()">
          Load more
        </button>
        <h5 class="Usermsg" *ngIf="noMoreRecordsAvailable">
          Thats it for now :)
        </h5>
      </div>
    </div>
   
    <div *ngIf="noResultReturned" class="img-div-left">
      <img
        alt="loading spinner"
        onContextMenu="return false;"
        class="spinner-global img-fluid"
        style="width: 100px; height: 100px; display: block; margin: 0px auto"
        src="../../../../assets/img/Spinner.gif"
      />
    </div>
  </div>
</section>
