import { ScratchcardComponent } from './../scratchcard/scratchcard.component';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-mreward',
  templateUrl: './mreward.component.html',
  styleUrls: ['./mreward.component.scss'],
})
export class MrewardComponent implements OnInit {
  userData: any;
  profilePic: any;
  showImage: boolean;
  rewards: any;
  isCardFlip: boolean;
  selectedReward: any;

  constructor(
    private titleAndMetaService: TitleAndMetaService,
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);

    if (this.userData != null) {
      if (this.userData.LoggedInUser.ProfilePic != null) {
        this.profilePic = this.userData.LoggedInUser.ProfilePic;
        console.log(this.profilePic);
        this.showImage = true;
      } else {
        this.showImage = false;
      }
      this.getMyRewards();
      this.titleAndMetaService.setTitle('My Rewards Page');
      this.titleAndMetaService.setMetaTag();
    }
  }

  getMyRewards() {
    this.rewards = [];
    this.tipService
      .getMyRewards(this.userData.LoggedInUser.UserEmail)
      .subscribe(async (data) => {
        console.log(data);
        data.MyRewards.forEach((element) => {
          console.log(element);
          this.rewards.push(element);
        });
        console.log(this.rewards);
      });
  }

  openDialog(reward) {
    console.log(reward);
    localStorage.setItem('reward', JSON.stringify(reward));
    const dialogRef = this.dialog.open(ScratchcardComponent);
  }

  flipCard(reward) {
    console.log(reward);
    reward.IsRewardFlipped = !reward.IsRewardFlipped ? true : false;

    this.tipService
      .postRewardFlipped(reward, this.userData.LoggedInUser.UserEmail)
      .subscribe(async (data) => {});
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
