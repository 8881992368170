<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>Sign Up</h2>
  </div>
</section>
<section (contextmenu)="onRightClick($event)">
  <div class="Login">
    <div align="center">
      <div id="formContent" class="formContent col-12">
        <!-- Tabs Titles -->

        <!-- Icon -->
        <div class="fadeIn first">
          <!-- <h4 class="top-head blk">Sign-Up</h4> -->
        </div>
        <div *ngIf="lastImage" class="selected-image">
          <img
            onContextMenu="return false;"
            class="rounded-circle"
            *ngIf="lastImage"
            [src]="lastImage"
            style="border-radius: 50%; height: 100px; width: 100px"
          />
        </div>
        <div class="file-wrap">
          <label for="selectimage">Choose profile picture</label><br />
          <input
            class="custom-file-input"
            #imageInput
            name="selectimage"
            type="file"
            accept="image/*"
            (change)="selectFiles($event)"
            #fileInput
          />
        </div>
        <!-- Login Form -->
        <form [formGroup]="registerForm">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              formControlName="name"
              [(ngModel)]="name"
              [ngClass]="{
                'is-invalid':
                  registerForm.get('name').touched &&
                  registerForm.get('name').invalid
              }"
              name="login"
              required
              class="fadeIn second"
              placeholder="Name"
            />
          </div>
          <div class="form-group">
            <div
              *ngIf="
                registerForm.controls['name'].invalid &&
                (registerForm.controls['name'].dirty ||
                  registerForm.controls['name'].touched)
              "
              class="alert alert-danger"
            >
              Full name is required.
            </div>
          </div>

          <div class="form-group">
            <input
              (focusout)="IsUserNameTaken()"
              type="text"
              class="form-control"
              formControlName="userName"
              [(ngModel)]="userName"
              [ngClass]="{
                'is-invalid':
                  registerForm.get('userName').touched &&
                  registerForm.get('userName').invalid
              }"
              name="login"
              required
              class="fadeIn second"
              placeholder="Username"
            />
          </div>

          <div class="form-group">
            <div
              *ngIf="
                registerForm.controls['userName'].invalid &&
                (registerForm.controls['userName'].dirty ||
                  registerForm.controls['userName'].touched)
              "
              class="alert alert-danger"
            >
              Username is required.
            </div>
          </div>

          <div class="form-group">
            <div
              style="color: red"
              class="alert alert-danger"
              *ngIf="isCapsLockPressed"
            >
              CapsLock is on. Username should start with small letters.
            </div>
          </div>

          <div class="form-group">
            <div
              style="color: red"
              *ngIf="isUserNameTaken"
              class="alert alert-danger"
            >
              That username is taken. Try another.
            </div>

            <div
              style="color: red"
              class="error"
              class="alert alert-danger"
              *ngIf="
                registerForm.get('userName').hasError('validateUserName') &&
                registerForm.get('userName').touched
              "
            >
              Invalid username. <br />
              Valid username may only contain alphabets and numbers. Underscore
              and full stop may only be used once in between characters.
            </div>
          </div>

          <div class="form-group">
            <input
              type="email"
              class="form-control"
              (focusout)="IsUserEmailTaken()"
              formControlName="email"
              [(ngModel)]="email"
              [ngClass]="{
                'is-invalid':
                  registerForm.get('email').touched &&
                  registerForm.get('email').invalid
              }"
              name="login"
              required
              class="fadeIn second"
              placeholder="Email Address"
            />
          </div>

          <div class="form-group">
            <div
              *ngIf="
                registerForm.controls['email'].invalid &&
                (registerForm.controls['email'].dirty ||
                  registerForm.controls['email'].touched)
              "
              class="alert alert-danger"
            >
              Email Address is required.
            </div>
            <div
              *ngIf="
                !isEmailValid &&
                (registerForm.controls['email'].dirty ||
                  registerForm.controls['email'].touched)
              "
              class="alert alert-danger"
            >
              Please enter valid email address.
            </div>
          </div>

          <div class="form-group">
            <div style="color: red" *ngIf="userEmailTaken">
              That email address is taken. Try another.
            </div>
          </div>

          <div class="form-group">
            <input
              type="text"
              maxlength="10"
              class="form-control"
              formControlName="phone"
              [(ngModel)]="phone"
              [ngClass]="{
                'is-invalid':
                  registerForm.get('phone').touched &&
                  registerForm.get('phone').invalid
              }"
              name="login"
              required
              class="fadeIn second"
              placeholder="Phone number"
            />
          </div>

          <div class="form-group">
            <div
              *ngIf="
                registerForm.controls['phone'].invalid &&
                (registerForm.controls['phone'].dirty ||
                  registerForm.controls['phone'].touched)
              "
              class="alert alert-danger"
            >
              Phone number is required.
            </div>
            <div
              *ngIf="
                !isPhoneValid &&
                (registerForm.controls['phone'].dirty ||
                  registerForm.controls['phone'].touched)
              "
              class="alert alert-danger"
            >
              Please enter valid phone number.
            </div>
          </div>

          <div class="form-group">
            <input
              type="password"
              class="form-control"
              formControlName="password"
              [(ngModel)]="password"
              [ngClass]="{
                'is-invalid':
                  registerForm.get('password') && registerForm.get('password')
              }"
              name="login"
              required
              class="fadeIn second"
              placeholder="Password"
            />
          </div>

          <div class="form-group">
            <div
              *ngIf="
                registerForm.controls['password'].invalid &&
                (registerForm.controls['password'].dirty ||
                  registerForm.controls['password'].touched)
              "
              class="alert alert-danger"
            >
              Password is required.
            </div>
            <label
              class="text-danger"
              *ngIf="registerForm.controls['password'].hasError('hasNumber')"
            >
              Must have at least 1 number!
            </label>
            <label
              class="text-danger"
              *ngIf="registerForm.controls['password'].hasError('minlength')"
            >
              Must be at least 8 characters!
            </label>

            <label
              class="text-danger"
              *ngIf="
                registerForm.controls['password'].hasError('hasCapitalCase')
              "
            >
              Must contain at least 1 in Capital Case!
            </label>

            <label
              class="text-danger"
              *ngIf="registerForm.controls['password'].hasError('hasSmallCase')"
            >
              Must contain at least 1 Letter in Small Case!
            </label>

            <label
              class="text-danger"
              *ngIf="
                registerForm.controls['password'].hasError(
                  'hasSpecialCharacters'
                )
              "
            >
              Must contain at least 1 Special Character!
            </label>
          </div>

          <div class="form-group">
            <input
              type="password"
              class="form-control"
              formControlName="confirmPassword"
              [(ngModel)]="confirmPassword"
              [ngClass]="{
                'is-invalid':
                  registerForm.get('confirmPassword') &&
                  registerForm.get('confirmPassword')
              }"
              name="login"
              required
              class="fadeIn second"
              placeholder="Confirm Password"
            />
          </div>

          <div class="form-group">
            <div
              *ngIf="
                registerForm.controls['confirmPassword'].invalid &&
                (registerForm.controls['confirmPassword'].dirty ||
                  registerForm.controls['confirmPassword'].touched)
              "
              class="alert alert-danger"
            >
              Confirm your password.
            </div>
          </div>
          <div align="center" style="width: 100%">
            <!-- <re-captcha
              class="g-recaptcha"
              (resolved)="addTokenLog('Normal mode resolved', $event)"
            ></re-captcha> -->
          </div>
          <br />
          <div class="chkbx">
            <mat-checkbox
              class="example-margin"
              (change)="tandcChecked()"
              [(ngModel)]="isTermsAndConditionChecked"
              [ngModelOptions]="{ standalone: true }"
              >By checking here and continuing. You agree to the AI Comparison
              <a routerLink="/tandc">Terms of Use</a></mat-checkbox
            >
          </div>

          <div class="form-group" id="formFooter">
            <button
              routerLink="/login"
              type="button"
              class="btn btn-primary btn-lg"
            >
              Login
            </button>

            <button
              [disabled]="!registerForm.valid || isSetDisabled || !token"
              style="border: none"
              type="submit"
              (click)="register()"
              class="btn btn-primary btn-lg"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
