<div class="common-banner mobileonly" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
      <h2>Membership Plans that helps you to grow parallelly</h2>
    </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>About Us</h2>
  </div>
</section>
<section (contextmenu)="onRightClick($event)">
  <div class="container">
    <div class="common-text text-center">
      <h2 class="blk">Welcome to AIComparison know more About us</h2>
      <p
        class="blk"
        style="font-size: 20px !important; font-weight: bold !important"
      >
        "We help compare the AI platforms”
      </p>
    </div>
  </div>
  <div class="twocol-tile about-col">
    <div class="twocol-tilewrap">
      <div class="row">
        <div class="col-sm-6">
          <div class="tile">
            <div class="image">
              <img src="../../../assets/img/about-1.jpg" />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="tile">
            <div class="coltile-text">
              <!-- <h6 class="blk">Who we are</h6> -->
              <!-- <h4 class="blk">About Us for YOUiTIP</h4> -->
              <ul class="bullet">
                <li>
                  <span><i class="fa fa-check" aria-hidden="true"></i></span>We
                  are providing the platform where people get quick answer to
                  their queries about the AI platforms
                </li>
                <li>
                  <span><i class="fa fa-check" aria-hidden="true"></i></span>The
                  comapre feture on the site provides quick and useful way to
                  find the information about AI platform that will fulfil user
                  needs
                </li>
                <li>
                  <span><i class="fa fa-check" aria-hidden="true"></i></span
                  >With AIComparison you can save your time required to research
                  the on the platform
                </li>
              </ul>

              <h4 class="blk">Quality Content</h4>
              <p>
                We try and keep the content up to date to maintain the high
                quality and unsful details about the AI platform.
              </p>
              <p>
                With AI Comparison, you have reference to all the data about the
                AI platforms.
              </p>
              <p>
                With AI Comparison, everything is available in the app which can
                be used anytime, anywhere, and on any device that users want to
                know or search.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="tile">
            <div class="coltile-text">
              <p>
                With AIComparison, everything is available in the app which can be
                used anytime, anywhere, and on any device that users want to
                know or search.
              </p>
              <h2 class="extra-bold blk">
                We are good people please
                <a
                  class="hyperlink"
                  style="cursor: pointer"
                  (click)="routeToPage('contactus')"
                  >Click Here</a
                >
                to connect with us
              </h2>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="tile">
            <div class="image">
              <img src="../../../assets/img/about-2.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="cat-parent about-category">
  <div class="cat-wrap">
    <h2>How it Works</h2>
    <p>Easy steps to know how AIComparison works.</p>
    <div class="cat-tile-wrap">
      <div class="row" style="width: 100%">
        <div class="col-sm-4">
          <img src="../../../assets/img/fav-new-ico.png" />
          <h6>STEP 1</h6>
          <h5>Add the platforms you would like to compare</h5>
          <!-- <p>
            Please
            <a
              class="hyperlink"
              style="cursor: pointer"
              (click)="routeToPage('register')"
              >register</a
            >
            on youITip and setup your profile
          </p> -->
        </div>
        <div class="col-sm-4">
          <img src="../../../assets/img/fav-new-ico.png" />
          <h6>STEP 2</h6>
          <h5>Select the compare platform icon in the top header section</h5>
          <!-- <p>
            <a
              class="hyperlink"
              style="cursor: pointer"
              (click)="routeToPage('login')"
              >Login</a
            >
            with your profile and click
            <a
              class="hyperlink"
              style="cursor: pointer"
              (click)="routeToPage('createtip')"
            >
              Create/Post Tips</a
            >
            to create tips
          </p> -->
        </div>
        <div class="col-sm-4">
          <img src="../../../assets/img/fav-new-ico.png" />
          <h6>STEP 3</h6>
          <h5>
            Thats all!. Compare the platform details and always select better
          </h5>
          <!-- <p>
            Download YouITip app from playstore and share the tips
            <a
              class="hyperlink"
              href="https://play.google.com/store/apps/details?id=io.ionic.youitip"
              >YouITip</a
            >
          </p> -->
        </div>
      </div>
    </div>
    <!-- <div class="btn">
      <button>View All Categories</button>
    </div> -->
  </div>
</div>
<div class="twocol-tip">
  <div class="twocol-tipwrap">
    <div class="row">
      <div class="col-sm-5">
        <!-- <img
          src="https://swash2021.azureedge.net/products/16368034265778bdadc6d-418d-4d9e-8b80-f71bb50a23b4.jpg"
        /> -->
        <img src="../../../assets/img/latest-news.jpg" />
      </div>
      <div class="col-sm-7 flex-d">
        <div class="col-text">
          <h2 class="blk">Why Choose AIComparison</h2>
          <p>
            AIComparison helps to checkout the comprehensive comparison of the
            AI Platforms.
          </p>
          <h3 class="blk">
            AI Comparison provides the information on below listed parameters
            for you to Always select better
          </h3>
          <ul class="tick">
            <li>
              <span
                ><i class="fa fa-check" aria-hidden="true"></i>Platforms
                Details</span
              >
            </li>
            <li>
              <span
                ><i class="fa fa-check" aria-hidden="true"></i>Platforms
                Supported</span
              >
            </li>
            <li>
              <span
                ><i class="fa fa-check" aria-hidden="true"></i>Audience</span
              >
            </li>
            <li>
              <span><i class="fa fa-check" aria-hidden="true"></i>Support</span>
            </li>
            <li>
              <span><i class="fa fa-check" aria-hidden="true"></i>API</span>
            </li>
            <li>
              <span><i class="fa fa-check" aria-hidden="true"></i>Pricing</span>
            </li>
            <!-- <li>
              <span><i class="fa fa-check" aria-hidden="true"></i></span>To see
              your connections only tips endusers are required to purchase the
              YouITip platform subscription by visiting &nbsp;<a
                class="hyperlink"
                style="cursor: pointer"
                (click)="routeToPage('pricing')"
                >Pricing Plans</a
              >&nbsp;on YouITip
            </li>
            <li>
              <span><i class="fa fa-check" aria-hidden="true"></i></span>You
              earn more money with more followers
            </li> -->
          </ul>
          <!-- <div class="btn">
            <button>View All Categories</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
