import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import {
  OnExecuteData,
  OnExecuteErrorData,
  ReCaptchaV3Service,
} from 'ng-recaptcha';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  token: any;

  public recentToken = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public recentError?: { error: any };
  public readonly executionLog: Array<OnExecuteData | OnExecuteErrorData> = [];

  private allExecutionsSubscription: Subscription;
  private allExecutionErrorsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;

  // Survey data
  surveyData = {
    frequencyOfUse: '',
    durationOfUse: '',
    satisfactionLevel: '',
    likedFeatures: '',
    improvements: '',
    recentToken: '',
  };

  // Options for select fields
  frequencyOptions = ['Daily', 'Weekly', 'Monthly', 'Occasionally', 'Rarely'];

  durationOptions = [
    'Less than a month',
    '1-3 months',
    '3-6 months',
    '6-12 months',
    'More than a year',
  ];

  satisfactionOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  likedFeaturesOptions = [
    'User interface and design',
    'Performance and speed',
    'Reliability and stability',
    'Versatility and flexibility',
    'Customer support',
    'Integration with other tools',
    'Pricing and value for money',
    'Other (please specify)',
  ];

  improvementsOptions = [
    'Enhanced performance and speed',
    'More intuitive user interface',
    'Additional features and functionalities',
    'Better compatibility with other devices/platforms',
    'Improved customer support response time',
    'Lower pricing or better value for money',
    'Bug fixes and stability improvements',
    'Other (please specify)',
  ];

  constructor(
    private router: Router,
    private titleAndMetaService: TitleAndMetaService,
    private formBuilder: UntypedFormBuilder,
    private tipService: TipService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.token = null;
  }

  ngOnInit() {
    this.allExecutionsSubscription =
      this.recaptchaV3Service.onExecute.subscribe((data) =>
        this.executionLog.push(data)
      );
    this.allExecutionErrorsSubscription =
      this.recaptchaV3Service.onExecuteError.subscribe((data) =>
        this.executionLog.push(data)
      );
  }

  // Function to submit the survey
  submitSurvey() {
    this.surveyData.recentToken = this.recentToken;
    // Here, you can handle the survey data as you desire, e.g., send it to the backend or perform further actions.
    this.tipService.saveSurvey(this.surveyData).subscribe(async (data) => {
      this.notificationService.showSuccess('Record saved successfully');
    });
  }

  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service
      .execute(action)
      .subscribe(
        (token) => {
          this.recentToken = token;
          this.submitSurvey();
        },
        (error) => {
          this.recentToken = '';
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          this.recentError = { error };
        }
      );
  }

  public ngOnDestroy(): void {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.allExecutionErrorsSubscription) {
      this.allExecutionErrorsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  public formatToken(token: string): string {
    if (!token) {
      return '(empty)';
    }

    return `${token.substr(0, 7)}...${token.substr(-7)}`;
  }
}
