<div class="common-banner">
  <img src="../../../assets/img/aboutus-banner.png" />
</div>
<section class="common-overlap">
  <div class="common-text">
    <h2>Platform details 13 key categories to distinguish</h2>
  </div>
</section>
<section class="plan-and-pricing">
  <!-- <div class="first-row-title">
    <div class="first-inner-row">
      <h5>Platform details <span>13 key categories to distinguish</span></h5>
    </div>
  </div> -->
  <div class="first-row" *ngFor="let tip of tips">
    <div *ngIf="tip.Prop1 == 'Platform'">
      <div class="first-inner-row title-row">{{ tip.Prop1 }}</div>
      <div class="first-inner-row">
        <img src="{{ tip.Prop2 }}" /><span class="close"
          ><i
            *ngIf="show1"
            (click)="removePlatformFromComparison(tips[14].Prop2, 1)"
            class="fa fa-times"
          ></i
        ></span>
      </div>
      <div class="first-inner-row">
        <img src="{{ tip.Prop3 }}" /><span class="close"
          ><i
            *ngIf="show2"
            (click)="removePlatformFromComparison(tips[14].Prop3, 2)"
            class="fa fa-times"
          ></i
        ></span>
      </div>
      <div class="first-inner-row">
        <img src="{{ tip.Prop4 }}" /><span class="close"
          ><i
            *ngIf="show3"
            (click)="removePlatformFromComparison(tips[14].Prop4, 3)"
            class="fa fa-times"
          ></i
        ></span>
      </div>
      <div class="first-inner-row">
        <img src="{{ tip.Prop5 }}" /><span class="close"
          ><i
            *ngIf="show4"
            (click)="removePlatformFromComparison(tips[14].Prop5, 4)"
            class="fa fa-times"
          ></i
        ></span>
      </div>
    </div>
    <div *ngIf="tip.Prop1 != 'Platform' && tip.Prop1 != 'RowKey'">
      <div class="first-inner-row">{{ tip.Prop1 }}</div>
      <div class="first-inner-row">{{ tip.Prop2 }}</div>
      <div class="first-inner-row">{{ tip.Prop3 }}</div>
      <div class="first-inner-row">{{ tip.Prop4 }}</div>
      <div class="first-inner-row">{{ tip.Prop5 }}</div>
    </div>
  </div>
</section>
