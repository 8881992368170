import { TipService } from 'src/app/sharedService/tip.service';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import {
  OnExecuteData,
  OnExecuteErrorData,
  ReCaptchaV3Service,
} from 'ng-recaptcha';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: UntypedFormGroup;
  public email: string;
  public password: string;
  userSetting: any;
  AIComparisonLoggedInUser: any;
  userData: any;
  userName: any;
  Password: any;
  loginFailed: any;
  noResultReturned: boolean;
  token: any;

  public recentToken = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public recentError?: { error: any };
  public readonly executionLog: Array<OnExecuteData | OnExecuteErrorData> = [];

  private allExecutionsSubscription: Subscription;
  private allExecutionErrorsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;

  constructor(
    private router: Router,
    public formbuilder: UntypedFormBuilder,
    private tipService: TipService,
    private dataService: DataService,
    // tslint:disable-next-line:use-lifecycle-interface
    private titleAndMetaService: TitleAndMetaService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.token = null;
    this.userData = localStorage.removeItem('AIComparisonLoggedInUser');
  }

  ngOnInit(): void {
    this.allExecutionsSubscription =
      this.recaptchaV3Service.onExecute.subscribe((data) =>
        this.executionLog.push(data)
      );
    this.allExecutionErrorsSubscription =
      this.recaptchaV3Service.onExecuteError.subscribe((data) =>
        this.executionLog.push(data)
      );

    this.titleAndMetaService.setTitle('Login Page');
    this.titleAndMetaService.setMetaTag();
    this.loginForm = this.formbuilder.group({
      password: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  addTokenLog(message: string, token: string | null) {
    console.log(`${message}: ${this.formatToken(token)}`);
  }

  // tslint:disable-next-line:variable-name
  equalto(field_name: string | number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const isValid = control.root.value[field_name] === input;
      if (!isValid) {
        return { equalTo: { isValid } };
      } else {
        return null;
      }
    };
  }

  login() {
    this.noResultReturned = true;
    const loginModel = {
      UserEmail: this.email,
      Password: this.password,
      RecentToken: this.recentToken,
    };
    console.log(loginModel);
    this.tipService.login(loginModel).subscribe(async (data) => {
      console.log(data);
      this.noResultReturned = false;
      if (data.LoggedInUser != null) {
        if (data.LoggedInUser != null && data.LoggedInUser.IsActiveUser) {
          localStorage.setItem(
            'AIComparisonLoggedInUser',
            JSON.stringify(data)
          );
          this.userData = data.LoggedInUser;
          this.email = data.LoggedInUser.UserEmail;
          this.dataService.notifyAboutChange();
          this.router.navigate(['/home']);
        }
        if (data.LoggedInUser != null && !data.LoggedInUser.IsActiveUser) {
          this.router.navigate(['/activateaccount']);
        }
      } else {
      }
    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service
      .execute(action)
      .subscribe(
        (token) => {
          this.recentToken = token;
          this.login();
        },
        (error) => {
          this.recentToken = '';
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          this.recentError = { error };
        }
      );
  }

  public ngOnDestroy(): void {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.allExecutionErrorsSubscription) {
      this.allExecutionErrorsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  public formatToken(token: string): string {
    if (!token) {
      return '(empty)';
    }

    return `${token.substr(0, 7)}...${token.substr(-7)}`;
  }
}
