import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { TipService } from 'src/app/sharedService/tip.service';

export interface PeriodicElement {
  position: number;
  Parameter: string;
  Value1: string;
  Value2: string;
  Value3: string;
}

@Component({
  selector: 'app-compareai',
  templateUrl: './compareai.component.html',
  styleUrls: ['./compareai.component.scss'],
})
export class CompareaiComponent implements OnInit {
  tips: any = [];
  itemsToCompare: string;

  show1: boolean;
  show2: boolean;
  show3: boolean;
  show4: boolean;
  itemsRowKey: string[];

  constructor(
    private tipService: TipService,
    private router: Router,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.itemsToCompare = localStorage.getItem('itemsToCompare');
    console.log(this.itemsToCompare);
    this.getTips(this.itemsToCompare);
    // this.show1 = true;
    // this.show2 = true;
    // this.show3 = true;
    // this.show4 = true;
  }

  getTips(rowIndex) {
    this.show1 = false;
    this.show2 = false;
    this.show3 = false;
    this.show4 = false;

    this.tips = [];
    console.log(rowIndex);
    var items = rowIndex.split(';');
    console.log(items);
    var index = 0;
    items.forEach((element) => {
      index++;
      this.showCloseIcon(index, true);
    });
    this.tipService
      .getAIPlatformDetailsToComareHubItems(rowIndex, '')
      .subscribe((res) => {
        console.log(res);
        var index = 0;
        res.AIHubRecords.forEach((element) => {
          index++;

          this.tips.push(element);
        });

        console.log(this.tips);
      });
  }

  showCloseIcon(removeIconIndex, showHide) {
    console.log(removeIconIndex, showHide);
    if (removeIconIndex == 1) {
      this.show1 = showHide;
    }
    if (removeIconIndex == 2) {
      this.show2 = showHide;
    }
    if (removeIconIndex == 3) {
      this.show3 = showHide;
    }
    if (removeIconIndex == 4) {
      this.show4 = showHide;
    }
  }

  removePlatformFromComparison(rowKey, removeIconIndex) {
    this.showCloseIcon(removeIconIndex, false);
    console.log(rowKey);
    this.itemsToCompare = localStorage.getItem('itemsToCompare');

    this.itemsRowKey = this.itemsToCompare.split(';');
    console.log(this.itemsRowKey);
    var newItems = '';

    this.itemsRowKey.forEach((element) => {
      if (element != rowKey) {
        newItems = newItems + element + ';';
      }
    });

    console.log(newItems);

    this.itemsToCompare = newItems.substring(0, newItems.length - 1);
    console.log(this.itemsToCompare);

    if (this.itemsToCompare == '') {
      this.router.navigate(['/home']);
    }

    localStorage.setItem('itemsToCompare', this.itemsToCompare);
    this.getTips(this.itemsToCompare);
    this.dataService.notifyAboutResetItemToCompare();
  }
}
