import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subtitle',
  templateUrl: './subtitle.component.html',
  styleUrls: ['./subtitle.component.scss'],
})
export class SubtitleComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  SubTitle: any;
  @Input() rowKey: string;

  constructor() {}
  ngOnInit(): void {
    console.log('changes for blog list', this.rowKey);
    this.blog = JSON.parse(localStorage.getItem('selectedBlog'));
    this.SubTitle = this.blog.BlogSubTitleModels.filter(
      (b) => b.RowKey == this.rowKey
    )[0]?.SubTitle;
  }
  onRightClick($event) {}
  loadMoreblogs() {}
}
