import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  subjectNotifier: Subject<null> = new Subject<null>();

  subjectSearchNotifier: Subject<string> = new Subject<string>();
  subjectViewCountNotifier: Subject<any> = new Subject<any>();
  subjectCommentCountNotifier: Subject<any> = new Subject<any>();
  subjectLikeCountNotifier: Subject<any> = new Subject<any>();
  subjectSaveCountNotifier: Subject<any> = new Subject<any>();
  subjectShowFooterNotifier: Subject<string> = new Subject<string>();
  subjectHideFooterNotifier: Subject<string> = new Subject<string>();
  subjectAddToCompareNotifier: Subject<number> = new Subject<number>();
  subjectCompareNotifier: Subject<null> = new Subject<null>();
  subjectResetToCompareNotifier: Subject<number> = new Subject<number>();
  subjectresetItemToCompareSubscription: Subject<number> =
    new Subject<number>();

  constructor() {}

  notifyAboutChange() {
    this.subjectNotifier.next();
  }

  notifyAboutCompareChange() {
    this.subjectCompareNotifier.next();
  }

  notifyShowFooter(searchInput) {
    this.subjectShowFooterNotifier.next(searchInput);
  }

  notifyHideFooter(searchInput) {
    this.subjectHideFooterNotifier.next(searchInput);
  }

  notifyAboutSearch(searchInput) {
    this.subjectSearchNotifier.next(searchInput);
  }

  notifyAboutViewsCount(searchInput, count) {
    console.log(searchInput);
    this.subjectViewCountNotifier.next({
      searchInput: searchInput,
      count: count,
    });
  }

  notifyAboutCommentCount(searchInput, count) {
    console.log(searchInput);
    this.subjectCommentCountNotifier.next({
      searchInput: searchInput,
      count: count,
    });
  }

  notifyAboutLikeCount(searchInput, IsTipLiked) {
    console.log(searchInput);
    this.subjectLikeCountNotifier.next({
      searchInput: searchInput,
      IsTipLiked: IsTipLiked,
    });
  }

  notifyAboutSaveCount(searchInput, IsTipSaved) {
    console.log(searchInput);
    this.subjectSaveCountNotifier.next({
      searchInput: searchInput,
      IsTipSaved: IsTipSaved,
    });
  }

  notifyAboutAddToCompare(searchInput) {
    this.subjectAddToCompareNotifier.next(searchInput);
  }

  notifyAboutResetToCompare() {
    this.subjectResetToCompareNotifier.next();
  }

  notifyAboutResetItemToCompare() {
    this.subjectresetItemToCompareSubscription.next();
  }
}
