import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as braintree from 'braintree-web';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  hostedFieldsInstance: braintree.HostedFields;

  cardholdersName: string;

  // Personal Info
  name: string;
  email: string;
  countryCode: string;
  postcode: string;

  bfname: string;
  baddressline2: string;
  baddressline1: string;
  bpostcode: string;
  bcity: string;
  billingCountryCode: any;

  planData: any;
  userData: any;
  noResultReturned: boolean;
  constructor(
    public router: Router,
    public tipServiceService: TipService,
    public notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    var loggedInUser = localStorage.getItem('AIComparisonLoggedInUser');
    if (loggedInUser != null && loggedInUser != undefined) {
      this.userData = JSON.parse(loggedInUser);
      console.log(this.userData);
      if (this.userData != null) {
        this.name = this.userData.LoggedInUser.Name;
      } else {
        this.router.navigate(['/login']);
      }
    }
    this.planData = JSON.parse(localStorage.getItem('checkoutplan'));
    console.log(this.planData);

    if (this.planData != null) {
      this.setCustomerDetails();
      //this.createBraintreeUI();
      this.payumethod();
      console.log(this.email, this.planData.Price, this.planData.planId);
    } else {
      // this.router.navigate(["/grid"]);
    }
  }

  setCustomerDetails() {
    console.log(this.userData);
    this.name = this.userData.LoggedInUser.UserName;
    this.email = this.userData.LoggedInUser.UserEmail;
  }

  payumethod() {
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);
    console.log(this.email, this.planData.Price, this.planData.planId);

    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      this.tipServiceService
        .getpayupaymentdetails(
          this.email,
          this.planData.Price,
          this.planData.planId
        )
        .subscribe(async (data: { payInfoDetails: any }) => {
          const product = data.payInfoDetails;
          console.log(product);

          if (product.phone != null) {
            const paymentDetails = {
              payu_url: product.payu_url,
              first_name: product.first_name,
              email: product.email,
              mobile: product.phone,
              callback_url: product.call_back_url,
              payu_cancel_url: product.payu_cancel_url,
              payu_fail_url: product.payu_fail_url,
              payu_merchant_key: product.payu_merchant_key,
              payu_sha_token: product.hashString,
              hashstringstring: product.hashstringbeforehash,
              txnid: product.txnId,
              plan_name: product.plan_name,
              amount: product.amount,
              service_provider: product.service_provide,
            };

            let paymentString = `
          <html>
            <body>
              <form action="${paymentDetails.payu_url}" method="post" id="payu_form">
                <input type="hidden" name="firstname" value="${paymentDetails.first_name}"/>
                <input type="hidden" name="email" value="${paymentDetails.email}"/>
                <input type="hidden" name="phone" value="${paymentDetails.mobile}"/>
                <input type="hidden" name="surl" value="${paymentDetails.callback_url}"/>
                <input type="hidden" name="curl" value="${paymentDetails.payu_cancel_url}"/>
                <input type="hidden" name="furl" value="${paymentDetails.payu_fail_url}"/>
                <input type="hidden" name="curl" value="${paymentDetails.payu_cancel_url}"/>
                <input type="hidden" name="key" value="${paymentDetails.payu_merchant_key}"/>
                <input type="hidden" name="hash" value="${paymentDetails.payu_sha_token}"/>
                <input type="hidden" name="txnid" value="${paymentDetails.txnid}"/>
                <input type="hidden" name="productinfo" value="${paymentDetails.plan_name}"/>
                <input type="hidden" name="amount" value="${paymentDetails.amount}"/>
                <input type="hidden" name="hashstringstring" value="${paymentDetails.hashstringstring}"/>
                <button type="submit" value="submit" #submitBtn></button>
              </form>
              <script type="text/javascript">document.getElementById("payu_form").submit();</script>
            </body>
          </html>`;

            const winUrl = URL.createObjectURL(
              new Blob([paymentString], { type: 'text/html' })
            );

            window.location.href = winUrl;
          } else {
            this.notificationService.showSuccess(
              'Please add your phone number'
            );
            this.router.navigate(['/meditprofile']);
          }
        });
    }
  }

  createBraintreeUI() {
    braintree.client
      .create({
        authorization: 'sandbox_rzcfg8vr_f24w944tczxnfx65',
      })
      .then((clientInstance) => {
        braintree.dataCollector.create(
          {
            client: clientInstance,
          },
          function (err, dataCollectorInstance) {
            if (err) {
              // Handle error in creation of data collector
              return;
            }
            // At this point, you should access the dataCollectorInstance.deviceData value and provide it
            // to your server, e.g. by injecting it into your form as a hidden input.
            console.log(dataCollectorInstance);
            console.log(dataCollectorInstance.deviceData);
          }
        );

        braintree.hostedFields
          .create({
            client: clientInstance,
            styles: {
              // Override styles for the hosted fields
            },

            // The hosted fields that we will be using
            // NOTE : cardholder's name field is not available in the field options
            // and a separate input field has to be used incase you need it
            fields: {
              number: {
                selector: '#card-number',
                placeholder: '1111 1111 1111 1111',
              },
              cvv: {
                selector: '#cvv',
                placeholder: '111',
              },
              expirationDate: {
                selector: '#expiration-date',
                placeholder: 'MM/YY',
              },
            },
          })
          .then((hostedFieldsInstance) => {
            this.hostedFieldsInstance = hostedFieldsInstance;

            hostedFieldsInstance.on('focus', (event) => {
              const field = event.fields[event.emittedBy];
              const label = this.findLabel(field);
              label.classList.remove('filled'); // added and removed css classes
              // can add custom code for custom validations here
            });

            hostedFieldsInstance.on('blur', (event) => {
              const field = event.fields[event.emittedBy];
              const label = this.findLabel(field); // fetched label to apply custom validations
              // can add custom code for custom validations here
            });

            hostedFieldsInstance.on('empty', (event) => {
              const field = event.fields[event.emittedBy];
              // can add custom code for custom validations here
            });

            hostedFieldsInstance.on('validityChange', (event) => {
              const field = event.fields[event.emittedBy];
              const label = this.findLabel(field);
              if (field.isPotentiallyValid) {
                // applying custom css and validations
                label.classList.remove('invalid');
              } else {
                label.classList.add('invalid');
              }
              // can add custom code for custom validations here
            });
          });
      });
  }

  // Tokenize the collected details so that they can be sent to your server
  // called from the html when the 'Pay' button is clicked
  tokenizeUserDetails() {
    this.noResultReturned = true;
    this.hostedFieldsInstance
      .tokenize({ cardholderName: this.cardholdersName })
      .then((payload) => {
        console.log(payload.nonce);
        // submit payload.nonce to the server from here

        if (this.userData != null) {
          var checkoutModel = {
            PaymentMethodNonce: payload.nonce,
            ContentId: this.planData.planId,
            CustomerModel: {
              EmailAddress: this.email,
              Address: {
                AddressLine1: this.baddressline1,
                AddressLine2: this.baddressline2,
                City: this.bcity,
                PinCode: this.bpostcode,
                Country: this.billingCountryCode,
              },
            },
            Price: this.planData.Price,
          };

          console.log(checkoutModel);

          // this.spinner.show();
          this.tipServiceService
            .tipCheckout(checkoutModel, this.userData.EmailAddress)
            .subscribe((data) => {
              //this.toastr.success("You purchased the flow successfully");
              this.noResultReturned = false;
              this.router.navigate(['/home']);
            });
        } else {
          this.router.navigate(['/login']);
        }
      })
      .catch((error) => {
        console.log(error);
        // perform custom validation here or log errors
      });
  }

  // Fetches the label element for the corresponding field
  findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
    return document.querySelector(
      '.hosted-field--label[for="' + field.container.id + '"]'
    );
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
