<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png">
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>Terms & Conditions</h2>
  </div>
</section>
<section (contextmenu)="onRightClick($event)">
<div class="container-fluid">
  <div class="container">
    <div class="policy-content">
      <h3 class="blk">AiComparison TERMS OF USE</h3>
      <h3 class="blk">Effective as of 07/07/2022</h3>
      <p>
        Welcome to AiComparison! We appreciate your interest in our services and products, and we strive to make your experience as seamless and enjoyable as possible. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions (“Terms and Conditions”). These Terms and Conditions govern your access to and use of AiComparison’s website, including any content, functionality, and services offered on or through the website.
      </p>
      <p>
        Please read these Terms and Conditions carefully before accessing or using our website. If you do not agree with these Terms and Conditions, you may not access or use our website. By accessing or using our website, you agree to be bound by these Terms and Conditions, including the terms of our Privacy Policy.
      </p>
     
      <h3 class="blk">Definitions</h3>
      <p>
        In these Terms and Conditions, the following words have the following meanings:
      </p>
      <p>a. “We,” “Us,” or “Our” refers to AiComparison, the company offering the services and products on our website.</p>
      <p>b. “You” or “Your” refers to any individual or entity that accesses or uses our website.</p>
      <p>c. “Services” refers to any services provided by AiComparison on our website.</p>
      <p>d. “Products” refers to any goods offered for sale on our website.</p>
      <p>e. “Content” refers to any text, images, videos, audio, or other material on our website.</p>
      <p>f. “User Account” refers to an account created by a user to access and use certain areas and features of our website.</p>
      <h3 class="blk">Eligibility</h3>
      <p>
        To access or use our website, you must be at least 18 years of age or older. If you are under 18, you may only access or use our website with the consent and supervision of a parent or legal guardian who agrees to be bound by these Terms and Conditions.
      </p>
      <h3 class="blk">User Account</h3>
      <p>
        To access and use certain areas and features of our website, you may need to create a user account. When creating a user account, you agree to provide accurate and complete information. You are solely responsible for maintaining the confidentiality of your account information, including your username and password. You agree to notify us immediately of any unauthorized access or use of your account.
      </p>
      <h3 class="blk">Intellectual Property Rights</h3>
      <p>The Content on our website, including all text, images, videos, audio, or other material, is owned by AiComparison or its licensors and is protected by intellectual property laws. You may not copy, reproduce, distribute, modify, display, perform, or create derivative works of any Content without our prior written consent.</p>
      <h3 class="blk">Use of our Website</h3>
      <p>You agree to use our website only for lawful purposes and in a manner that does not infringe the rights of any third party. You may not use our website to:</p>
      <p>a. Impersonate any person or entity, or falsely state or misrepresent your affiliation with any person or entity.</p>
      <p>b. Upload, post, email, or otherwise transmit any Content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, or hateful.</p>

        <p>c. Upload, post, email, or otherwise transmit any Content that you do not have a right to transmit under any law or under contractual or fiduciary relationships.</p>
        
        <p>d. Upload, post, email, or otherwise transmit any Content that infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party.</p>
        
       <p>e. Upload, post, email, or otherwise transmit any unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation.</p>
      <h3 class="blk">Orders and Payment</h3>
      <p>You may purchase Products on our website by placing an order through our online checkout process. By placing an order, you represent and warrant that you are authorized to use the payment method provided for the purchase. We reserve the right to refuse or cancel any order at any time for any reason, including but not limited to product availability, errors in the description or pricing of the product, or errors in your order.</p>
      <p>You agree to pay all charges incurred by you or on your behalf through our website, including all applicable taxes and shipping charges. We may use a third-party payment processor to process your payment, and your use of such third-party payment processor will be governed by their terms and conditions.</p>
      <h3 class="blk">Disclaimer of Warranties</h3>
      <p>OUR WEBSITE, CONTENT, SERVICES, AND PRODUCTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR ACCURACY. WE DO NOT WARRANT THAT YOUR USE OF OUR WEBSITE WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE.</p>
      <h3 class="blk">Limitation of Liability</h3>
      <p>IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OUR WEBSITE, CONTENT, SERVICES, OR PRODUCTS, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUE, PROFITS, OR BUSINESS, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR TOTAL LIABILITY TO YOU FOR ALL CLAIMS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND CONDITIONS SHALL NOT EXCEED THE AMOUNT PAID BY YOU FOR THE PRODUCTS OR SERVICES.</p>
        <h3 class="blk">Indemnification</h3>
        <p>You agree to indemnify and hold us and our affiliates, officers, directors, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms and Conditions or your violation of any law or the rights of any third party.</p>
        <h3 class="blk">Governing Law and Jurisdiction</h3>
        <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of the state of California, without giving effect to any principles of conflicts of law. Any dispute arising out of or in connection with these Terms and Conditions shall be resolved exclusively in the state or federal courts located in Los Angeles, California.</p>
        <h3 class="blk">Changes to These Terms and Conditions</h3>
        <p>We reserve the right to modify these Terms and Conditions at any time, without notice to you. Your continued use of our website after any changes to these Terms and Conditions will constitute your acceptance of the changes.</p>
        <h3 class="blk">Entire Agreement</h3>
        <p>These Terms and Conditions, together with our Privacy Policy, constitute the entire agreement between you and AiComparison regarding your use of our website, Content, Services, and Products. Any other agreements between you and AiComparison regarding your use of our website, Content, Services, or Products are superseded by these Terms and Conditions.</p>
        <p>Thank you for choosing AiComparison! If you have any questions or concerns about these Terms and Conditions, please contact us at [insert contact information]. We are committed to providing you with the best possible experience and value your feedback.</p>
    </div>
  </div>
</div>
</section>
