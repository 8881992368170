import { FollowersComponent } from './../followers/followers.component';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TipService } from 'src/app/sharedService/tip.service';
import { FollowingComponent } from '../following/following.component';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { ViewtipComponent } from '../viewtip/viewtip.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { CommentComponent } from '../comment/comment.component';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss'],
})
export class UserprofileComponent implements OnInit {
  userData: any;
  userDisplayName: string;
  userEmail: any;
  showImage: boolean;
  lastImage: any;
  myTipsCount: any;
  followerUserCount: any;
  followingUserCount: any;
  pageIndex: number;
  tips: any = [];
  noResultReturned: boolean;
  tipliked: boolean;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService,
    private _cdf: ChangeDetectorRef,
    private notificationService: NotificationService,
    private tipHelperService: TipHelperService
  ) {}

  ngOnInit(): void {
    this.noResultReturned = true;
    this.titleAndMetaService.setTitle('User Profile Page');
    this.titleAndMetaService.setMetaTag();
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    this.userEmail = '';
    console.log(this.userData);
    if (this.userData != null) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
      if (this.userData.LoggedInUser.ProfilePic != null) {
        this.lastImage = this.userData.LoggedInUser.ProfilePic;
        console.log(this.lastImage);
        this.showImage = true;
      } else {
        this.showImage = false;
      }
    }
    localStorage.setItem('youtipuserprofilepageIndex', '0');
    this.loadTips('0');
    this.pageIndex = 0;
    this.tipService.getMyCounts(this.userEmail).subscribe(async (data) => {
      this.myTipsCount = data.MyCounts.TipCount;
      this.followerUserCount = data.MyCounts.FollowerCount;
      this.followingUserCount = data.MyCounts.FollowingCount;
    });
  }

  notifierViewCountSubscription: Subscription =
    this.dataService.subjectViewCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.ViewCount = Input.count;
        this._cdf.detectChanges();
      }
    });

  notifierCommentCountSubscription: Subscription =
    this.dataService.subjectCommentCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.CommentCount = Input.count;
        this._cdf.detectChanges();
      }
    });

  notifierLikeCountSubscription: Subscription =
    this.dataService.subjectLikeCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('like found tip');
        console.log(tip.IsTipLiked);
        tip.IsTipLiked = Input.IsTipLiked;
        if (tip.IsTipLiked) {
          tip.LikeCount++;
        } else {
          tip.LikeCount--;
        }
        this._cdf.detectChanges();
      }
    });

  notifierSaveCountSubscription: Subscription =
    this.dataService.subjectSaveCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.IsTipSaved = Input.IsTipSaved;
        this._cdf.detectChanges();
      }
    });

  async loadTips(pageIndex) {
    if (this.userData != null) {
      this.dataService.notifyHideFooter('');
      this.tipService
        .getUserTips(pageIndex, this.userData.LoggedInUser.UserEmail)
        .subscribe(async (data) => {
          this.dataService.notifyShowFooter('');
          this.noResultReturned = false;
          data.UserTips.forEach((element) => {
            this.tips.push(element);
          });

          console.log(this.tips);
        });
    }
  }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      console.log(this.userData);
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        this.router.navigate(['/userprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/otheruserprofile']);
      }
    }
  }

  savedTips() {
    this.router.navigate(['/myprofile/savedtips']);
  }

  changePassword() {
    this.router.navigate(['/myprofile/changepassword']);
  }

  openDialog(tip) {
    localStorage.setItem('selectedTip', JSON.stringify(tip));
    const dialogRef = this.dialog.open(ViewtipComponent);
  }

  OpenSecondDialog() {
    const dialogRef = this.dialog.open(FollowingComponent);
  }

  tipComment(tip) {
    if (this.userData != null || this.userData != undefined) {
      localStorage.setItem('tipComment', JSON.stringify(tip));
      const dialogRef = this.dialog.open(CommentComponent);
    } else {
      this.router.navigate(['/login']);
    }
  }

  // onScrollDown() {
  //   //this.spinner.show();
  //   console.log('scrolled');
  //   //this.noResultReturned = true;
  //   this.delay(2000).then(() => {
  //     console.log('ondemand');
  //     this.delayedServiceCall();
  //   });
  // }
  // delay(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  tipByCategory(tip) {
    localStorage.setItem(
      'tipCategory',
      JSON.stringify({
        categoryName: tip.CategoryName,
        categoryId: tip.CategoryId,
      })
    );
    this.router.navigate(['/tipbycategory']);
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem('youtipuserprofilepageIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('youtipuserprofilepageIndex', pageIndex);
    console.log(pageIndex);
    this.loadTips(pageIndex);
  }

  async likeTip(tip) {
    if (this.userData != null) {
      var rowKey = this.tipHelperService.getRowKey(tip);
      this.tipService
        .likeTip(rowKey, this.userData.LoggedInUser.UserEmail, tip.LikeCount)
        .subscribe(async (data) => {
          console.log(data.IsTipLiked);
          if (data.IsTipLiked) {
            this.notificationService.showAnimation(this.tips, tip);
            tip.LikeCount++;
            this.tipliked = true;
            tip.IsTipLiked = true;
            setTimeout(() => {
              this.tipliked = false;
            }, 2000);
          } else {
            tip.LikeCount--;
            tip.IsTipLiked = false;
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveTip(tip) {
    console.log(tip);
    if (this.userData != null) {
      if (this.userData != null) {
        var rowKey = this.tipHelperService.getRowKey(tip);
        this.tipService
          .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
          .subscribe(async (data) => {
            console.log(data);
            tip.IsTipSaved = data.IsTipSaved;
            if (data.IsTipSaved) {
              this.notificationService.showAnimation(this.tips, tip);
              tip.SaveCount++;
              this.tipliked = true;
              setTimeout(() => {
                this.tipliked = false;
              }, 2000);
            } else {
              tip.SaveCount--;
            }
          });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  loadMoreTips() {
    this.delayedServiceCall();
  }

  viewTip(tip) {
    //localStorage.setItem('viewtip', JSON.stringify(this.tipDetails));

    localStorage.setItem('selectedTip', JSON.stringify(tip));
    this.router.navigate(['/viewtip']);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
