import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare var gtag: any;
// declare ga as a function to set and sent the events
declare var ga: any;
import { map, startWith, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'AIComparison - A platform helps to compare AI tools';

  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    const navEndEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-GFKHR6L0RB', {
        'page-path': event.urlAfterRedirects,
      });
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('event instanceof NavigationEnd');
        gtag('config', 'GTM-TW35R8X', {
          page_path: event.urlAfterRedirects,
        });
      }
    });

    // // subscribe to router events and send page views to Google Analytics
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('header').classList.add('fixed');
    } else {
      document.getElementById('header').classList.remove('fixed');
    }
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}

/**
 *
 */
