import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-mcategories',
  templateUrl: './mcategories.component.html',
  styleUrls: ['./mcategories.component.scss'],
})
export class McategoriesComponent implements OnInit {
  userData: any;
  userEmail: any;
  noResultReturned: boolean;
  categories: any = [];
  categoryId: any;
  tips: any = [];
  tipliked: boolean;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService,
    private tipHelperService: TipHelperService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('Categories Page');
    this.titleAndMetaService.setMetaTag();

    localStorage.removeItem('aihubcategorypageIndex');
    localStorage.setItem('aihubcategorypageIndex', '0');
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);

    if (this.userData != null) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
    } else {
      this.userEmail = '';
    }
    this.getCategories('0');
  }

  categoryClicked(category) {
    this.categoryId = category.RowKey;
    this.loadTips('0');
  }

  getCategories(pageIndex) {
    this.dataService.notifyHideFooter('');
    this.noResultReturned = true;
    this.tipService.getCategories(pageIndex).subscribe((res) => {
      console.log(res.Categories);
      this.dataService.notifyShowFooter('');
      res.Categories.forEach((element) => {
        this.categories.push(element);
      });
      this.categoryId = this.categories[0].RowKey;
      this.loadTips('0');
      this.noResultReturned = false;
    });
  }

  tipByCategory(category) {
    localStorage.setItem(
      'tipCategory',
      JSON.stringify({
        categoryName: category.Name,
        categoryId: category.RowKey,
      })
    );
    this.router.navigate(['/tipbycategory']);
  }

  onScrollDown() {
    //this.spinner.show();
    console.log('scrolled');
    //this.noResultReturned = true;
    this.delay(1000).then(() => {
      console.log('ondemand');
      this.delayedServiceCall();
    });
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem('aihubcategorypageIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('aihubcategorypageIndex', pageIndex);
    console.log(pageIndex);
    this.getCategories(pageIndex);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  loadTips(pageIndex) {
    this.noResultReturned = true;
    this.tipService
      .getCategoryTips(pageIndex, this.categoryId, this.userEmail)
      .subscribe(async (data) => {
        console.log(data);
        this.noResultReturned = false;

        data.CategoryTips.forEach((element) => {
          this.tips.push(element);
        });

        // this.categoryName = this.tips[0].CategoryName;

        console.log(this.tips);
      });
  }

  async likeTip(tip) {
    if (this.userData != null) {
      var rowKey = this.tipHelperService.getRowKey(tip);
      this.tipService
        .likeTip(rowKey, this.userData.LoggedInUser.UserEmail, tip.LikeCount)
        .subscribe(async (data) => {
          console.log(data.IsTipLiked);
          if (data.IsTipLiked) {
            this.notificationService.showAnimation(this.tips, tip);
            tip.LikeCount++;
            this.tipliked = true;
            tip.IsTipLiked = true;
            setTimeout(() => {
              this.tipliked = false;
            }, 2000);
          } else {
            tip.LikeCount--;
            tip.IsTipLiked = false;
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveTip(tip) {
    console.log(tip);
    if (this.userData != null) {
      if (this.userData != null) {
        var rowKey = this.tipHelperService.getRowKey(tip);
        this.tipService
          .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
          .subscribe(async (data) => {
            console.log(data);
            tip.IsTipSaved = data.IsTipSaved;
            if (data.IsTipSaved) {
              this.notificationService.showAnimation(this.tips, tip);
              tip.SaveCount++;
              this.tipliked = true;
              setTimeout(() => {
                this.tipliked = false;
              }, 2000);
            } else {
              tip.SaveCount--;
            }
          });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  viewTip(tip) {
    localStorage.setItem('selectedTip', JSON.stringify(tip));
    this.router.navigate(['/viewtip']);
  }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        console.log('myprofile');
        this.router.navigate(['myprofile/editprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        console.log('otheruserprofile');
        this.router.navigate(['otheruserprofile/otherusertips']);
      }
    }
  }

  viewMobileUserProfile(userEmail) {
    console.log('inviewmobileuser');
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        this.router.navigate(['/mytips']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/mothertips']);
      }
    }
  }

  loadMoreTips() {
    this.delayedServiceCall();
  }
}
