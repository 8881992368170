import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
})
export class ParagraphComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  paraText: any;
  @Input() rowKey: string;

  constructor() {}
  ngOnInit(): void {
    this.blog = JSON.parse(localStorage.getItem('selectedBlog'));
    this.paraText = this.blog.BlogParagraphs.filter(
      (b) => b.RowKey == this.rowKey
    )[0]?.ParaText;
  }
  onRightClick($event) {}
  loadMoreblogs() {}
}
