<div class="container-fluid banner-wrap hidediv" (contextmenu)="onRightClick($event)">
  <owl-carousel
    [options]="SlideOptions"
    [carouselClasses]="['owl-theme', 'sliding']"
  >
    <div class="item">
      <div class="img-slide">
        <img src="../../../assets/img/bgimage-old.jpg" />
      </div>
      <div style="align-content: center" class="text-palcement">
        <div class="jumbotron jumbotron-fluid banner-center main-banner">
          <div class="container-fluid">
            <div class="row banner-row">
              <div class="banner-text">
                <h1>Transforming <span>Your Experiences with AI</span>: Where Innovation Meets Intelligence!</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="item">
      <div class="img-slide">
        <img src="../../../assets/img/bgimage.jpg" />
      </div>
      <div style="align-content: center" class="text-palcement">
        <div class="jumbotron jumbotron-fluid banner-center main-banner">
          <div class="container-fluid position-relative">
            <div class="row banner-row">
              <div class="banner-text">
                <h1>
                  You are Rewarded for every
                  <span style="color: #b34bc9; font-weight: bolder"> 10</span>
                  tips!
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="img-slide">
        <img src="../../../assets/img/bgimage.jpg" />
      </div>
      <div style="align-content: center" class="text-palcement">
        <div class="jumbotron jumbotron-fluid banner-center main-banner">
          <div class="container-fluid position-relative">
            <div class="row banner-row">
              <div class="banner-text">
                <h1>
                  We create effective tips for all generations in the world
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="img-slide">
        <img src="../../../assets/img/about-2.jpg" />
      </div>
      <div style="align-content: center" class="text-palcement">
        <div class="jumbotron jumbotron-fluid banner-center main-banner">
          <div class="container-fluid position-relative">
            <div class="row banner-row">
              <div class="banner-text">
                <h1>Share Tips Earn Money</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </owl-carousel>
</div>

<div class="banner-text">
  <h1>Transforming <span>Your Experiences with AI</span>: Where<br/> Innovation Meets Intelligence!</h1>
</div>

<app-search></app-search>
