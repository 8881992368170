import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  pageIndex: any;
  noResultReturned: boolean;
  categories: any = [];
  category: any;
  users: any = [];
  user: any;
  selectedUsers: string;
  selectedCategories: string;
  userData: any;
  selectedItemsList = [];
  checkedIDs = [];
  checkboxesDataList = [];

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService
  ) {
    this.selectedUsers = '';
    this.selectedCategories = '';
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);
    this.getcategories('0', undefined);
    this.getusers('0', undefined);
    this.fetchSelectedItems();
  }

  addFilter() {
    if (this.selectedUsers == '' && this.selectedCategories == '') {
      localStorage.removeItem('filterUsersAndCategoriesStore');
      this.router.navigate(['/home']);
    } else {
      var selectedCategoriesAndUsers = {
        selectedUsers: this.selectedUsers,
        selectedCategories: this.selectedCategories,
      };
      console.log(selectedCategoriesAndUsers);
      localStorage.setItem(
        'selectedCategoriesAndUsers',
        JSON.stringify(selectedCategoriesAndUsers)
      );
      this.router.navigate(['/home']);
    }
  }

  clearFilter() {
    this.selectedUsers = '';
    this.selectedCategories = '';
    this.category = null;
    this.user = null;
    localStorage.removeItem('filterUsersAndCategoriesStore');
    location.reload();
  }

  getcategories(pageIndex, event) {
    this.tipService.getCategories(pageIndex).subscribe(async (data) => {
      console.log(data);
      this.noResultReturned = false;

      data.Categories.forEach((element) => {
        this.categories.push(element);
      });

      console.log('this.categories');
      console.log(event);

      if (event != undefined) {
        event.component.endInfiniteScroll();
      }
    });
  }

  getusers(pageIndex, event) {
    this.tipService.getUsers('', pageIndex).subscribe(async (data) => {
      console.log(data);
      this.noResultReturned = false;

      data.Users.forEach((element) => {
        this.users.push(element);
      });

      console.log(data.Users);

      if (event != undefined) {
        event.component.endInfiniteScroll();
      }
    });
  }

  // categoriesChangeportChange(event) {
  //   console.log(event);
  //   this.selectedCategories = event.value.map((o) => o.RowKey).join(';');
  //   console.log(this.selectedCategories);
  // }

  getMoreCategories(event) {
    console.log('pageIndex');
    this.pageIndex = localStorage.getItem('categoryIndexPageIndex');
    this.pageIndex = parseInt(this.pageIndex + 1);
    console.log(this.pageIndex);
    localStorage.setItem('categoryIndexPageIndex', this.pageIndex);
    this.getcategories(this.pageIndex, event);
  }

  getMoreUsers(event) {
    console.log('pageIndex');
    this.pageIndex = localStorage.getItem('userIndexPageIndex');
    this.pageIndex = parseInt(this.pageIndex + 1);
    console.log(this.pageIndex);
    localStorage.setItem('userIndexPageIndex', this.pageIndex);
    this.getusers(this.pageIndex, event);
  }

  getUsersChange(event) {
    this.selectedUsers = event.value.UserEmail;
    console.log(this.selectedUsers);
  }

  changeSelection() {
    this.fetchSelectedItems();
  }

  fetchSelectedItems() {
    this.selectedCategories = this.categories.filter((value, index) => {
      return value.isChecked;
    });
    console.log(this.selectedCategories);
  }

  changeUserSelection() {
    this.selectedUsers = this.users.filter((value, index) => {
      return value.isChecked;
    });
    console.log(this.selectedUsers);
  }

  fetchCheckedIDs() {
    this.checkedIDs = [];
    this.checkboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.id);
      }
    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
