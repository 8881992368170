import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    console.log(value);
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
    imports: [],
    declarations: [SafeHtmlPipe],
    exports: [SafeHtmlPipe]
  })
  export class SafeHtmlPipeModule { }