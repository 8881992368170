import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FooterApiService } from '../Services/footer-api.service';
import { MatDialog } from '@angular/material/dialog';
import { CreatetipComponent } from 'src/app/featureModule/createtip/createtip.component';
import { DataService } from 'src/app/sharedService/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  destroyTheSubject$: Subject<boolean> = new Subject<boolean>();
  footerContent = '';
  userData: any;
  hideFooter: boolean;
  userEmail: any;
  name: any;
  profilePic: any;
  
  constructor(
    private footerApiService: FooterApiService,
    private router: Router,
    public dialog: MatDialog,
    private dataService: DataService
  ) {
    this.hideFooter = false;
    this.registerLoggedInUser();
  }

  notifierShowSubscription: Subscription =
    this.dataService.subjectShowFooterNotifier.subscribe((searchInput) => {
      this.hideFooter = false;
    });

  notifierHideSubscription: Subscription =
    this.dataService.subjectHideFooterNotifier.subscribe((searchInput) => {
      this.hideFooter = true;
    });

  notifierSubscription: Subscription =
    this.dataService.subjectNotifier.subscribe((notified) => {
      this.registerLoggedInUser();
    });

  ngOnDestroy(): void {
    this.notifierSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getFooter();
  }

  getFooter(): void {
  }

  registerLoggedInUser() {
    var loggedInUser = localStorage.getItem('AIComparisonLoggedInUser');
    if (loggedInUser != null && loggedInUser != undefined) {
      this.userData = JSON.parse(loggedInUser);
      if (this.userData != null) {
        this.name = this.userData.LoggedInUser.Name;
        this.profilePic = this.userData.LoggedInUser.ProfilePic;
      }
    }
  }

  openCreateDialog() {
    console.log(this.userData);
    if (this.userData != null && this.userData != undefined) {
      this.router.navigate(['/createtip']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
