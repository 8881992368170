<div>
  <p>
    <ng-container *ngFor="let part of formattedTextParts">
      <ng-container [ngSwitch]="part.tag">
        <span *ngSwitchCase="'b'" class="bold">{{ part.text }}</span>
        <span *ngSwitchCase="'i'" class="italic">{{ part.text }}</span>
        <span *ngSwitchCase="'u'" class="underline">{{ part.text }}</span>
        <span *ngIf="isAnchorTag(part.tag)">
          <a href="{{ getHref(part.tag) }}">{{ part.text }}</a>
        </span>
        <ng-container *ngSwitchDefault>{{ part.text }}</ng-container>
      </ng-container>
    </ng-container>
  </p>
</div>
