import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { ViewtipComponent } from '../viewtip/viewtip.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { CommentComponent } from '../comment/comment.component';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';

@Component({
  selector: 'app-m-saved-tips',
  templateUrl: './m-saved-tips.component.html',
  styleUrls: ['./m-saved-tips.component.scss'],
})
export class MSavedTipsComponent implements OnInit {
  noResultReturned: boolean;
  userData: any;
  tips: any = [];
  userEmail: any;
  tipliked: boolean;

  constructor(
    private tipService: TipService,
    private router: Router,
    private titleAndMetaService: TitleAndMetaService,
    public dialog: MatDialog,
    private dataService: DataService,
    private _cdf: ChangeDetectorRef,
    private notificationService: NotificationService,
    private tipHelperService: TipHelperService
  ) {}

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('Saved Tips Page');
    this.titleAndMetaService.setMetaTag();
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);
    this.userEmail = this.userData.LoggedInUser.UserEmail;
    console.log(this.userEmail);

    localStorage.setItem('getMySavedTipsepageIndex', '0');
    this.getMySavedTips('0');
  }

  tipByCategory(tip) {
    localStorage.setItem(
      'tipCategory',
      JSON.stringify({
        categoryName: tip.CategoryName,
        categoryId: tip.CategoryId,
      })
    );
    this.router.navigate(['/tipbycategory']);
  }

  tipComment(tip) {
    if (this.userData != null || this.userData != undefined) {
      localStorage.setItem('tipComment', JSON.stringify(tip));
      const dialogRef = this.dialog.open(CommentComponent);
    } else {
      this.router.navigate(['/login']);
    }
  }

  getMySavedTips(pageIndex) {
    this.noResultReturned = true;
    this.dataService.notifyHideFooter('');
    this.tipService
      .getMySavedTips(pageIndex,this.userData.LoggedInUser.UserEmail)
      .subscribe(async (data) => {
        this.dataService.notifyShowFooter('');
        this.noResultReturned = false;
        console.log('Saved Tips');
        console.log(data);
        data.SavedTips.forEach((element) => {
          this.tips.push(element);
        });
      });
  }

  notifierViewCountSubscription: Subscription =
    this.dataService.subjectViewCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.ViewCount = Input.count;
        this._cdf.detectChanges();
      }
    });

  notifierCommentCountSubscription: Subscription =
    this.dataService.subjectCommentCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.CommentCount = Input.count;
        this._cdf.detectChanges();
      }
    });

  notifierLikeCountSubscription: Subscription =
    this.dataService.subjectLikeCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('like found tip');
        console.log(tip.IsTipLiked);
        tip.IsTipLiked = Input.IsTipLiked;
        if (tip.IsTipLiked) {
          tip.LikeCount++;
        } else {
          tip.LikeCount--;
        }
        this._cdf.detectChanges();
      }
    });

  notifierSaveCountSubscription: Subscription =
    this.dataService.subjectSaveCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.IsTipSaved = Input.IsTipSaved;
        this._cdf.detectChanges();
      }
    });

  openDialog(tip) {
    localStorage.setItem('selectedTip', JSON.stringify(tip));
    const dialogRef = this.dialog.open(ViewtipComponent);
  }

  onScrollDown() {
    //this.spinner.show();
    console.log('scrolled');
    //this.noResultReturned = true;
    this.delay(2000).then(() => {
      console.log('ondemand');
      this.delayedServiceCall();
    });
  }
  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem('getMySavedTipsepageIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('getMySavedTipsepageIndex', pageIndex);
    console.log(pageIndex);
    //this.getMySavedTips(pageIndex);
  }

  viewUserProfile(userEmail) {
    console.log('inviewmobileuser');
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        this.router.navigate(['/mytips']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/mothertips']);
      }
    }
  }

  async likeTip(tip) {
    if (this.userData != null) {
      var rowKey = this.tipHelperService.getRowKey(tip);
      this.tipService
        .likeTip(rowKey, this.userData.LoggedInUser.UserEmail, tip.LikeCount)
        .subscribe(async (data) => {
          console.log(data.IsTipLiked);
          if (data.IsTipLiked) {
            this.notificationService.showAnimation(this.tips, tip);
            tip.LikeCount++;
            this.tipliked = true;
            tip.IsTipLiked = true;
            setTimeout(() => {
              this.tipliked = false;
            }, 2000);
          } else {
            tip.LikeCount--;
            tip.IsTipLiked = false;
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveTip(tip) {
    console.log(tip);
    if (this.userData != null) {
      if (this.userData != null) {
        var rowKey = this.tipHelperService.getRowKey(tip);
        this.tipService
          .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
          .subscribe(async (data) => {
            console.log(data);
            tip.IsTipSaved = data.IsTipSaved;
            if (data.IsTipSaved) {
              this.notificationService.showAnimation(this.tips, tip);
              tip.SaveCount++;
              this.tipliked = true;
              setTimeout(() => {
                this.tipliked = false;
              }, 2000);
            } else {
              tip.SaveCount--;
            }
          });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  viewTip(tip) {
    localStorage.setItem('selectedTip', JSON.stringify(tip));
    this.router.navigate(['/viewtip']);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
