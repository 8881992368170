import { CreatetipComponent } from './../../../featureModule/createtip/createtip.component';
import { WhoisusingyoutipComponent } from './../../../featureModule/whoisusingyoutip/whoisusingyoutip.component';
import { ReelsComponent } from './../../../featureModule/reels/reels.component';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { youitipwebAppHeader } from 'src/app/models/YouITipAppHeader';
import { HeaderApiService } from './services/header-api.service';
import { DataService } from 'src/app/sharedService/data.service';
import { Subscription } from 'rxjs';
import { TipService } from 'src/app/sharedService/tip.service';
import { ViewtipComponent } from 'src/app/featureModule/viewtip/viewtip.component';
import { NotificationService } from 'src/app/sharedService/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  youitipwebAppHeaderModel: youitipwebAppHeader[];
  valueToBeSanitized: string;
  userEmail: any;
  name: any;
  userData: any;
  userDisplayName: any;
  turnOnNightMode: boolean;
  isAdmin: boolean;
  profilePic: any;
  pageIndex: number;
  notificationList: any = [];
  itemsToCompare: number;

  notifierSubscription: Subscription =
    this.dataService.subjectNotifier.subscribe((notified) => {
      this.registerLoggedInUser();
    });

  resetToCompareSubscription: Subscription =
    this.dataService.subjectResetToCompareNotifier.subscribe((notified) => {
      this.itemsToCompare = 0;
    });

  resetItemToCompareSubscription: Subscription =
    this.dataService.subjectresetItemToCompareSubscription.subscribe(
      (notified) => {
        if (this.itemsToCompare > 0) {
          this.itemsToCompare = this.itemsToCompare - 1;
        }
      }
    );

  notifierAboutCompareSubscription: Subscription =
    this.dataService.subjectCompareNotifier.subscribe((notified) => {
      this.compareAIPlatForm();
    });

  cClicked: boolean;
  cousClicked: boolean;
  abClicked: boolean;
  wiClicked: boolean;
  reClicked: boolean;
  prClicked: boolean;
  upClicked: boolean;
  fbClicked: boolean;

  constructor(
    private appHeaderApiService: HeaderApiService,
    private router: Router,
    public dialog: MatDialog,
    private dataService: DataService,
    private notificationService: NotificationService,
    private tipService: TipService
  ) {}

  registerLoggedInUser() {
    var loggedInUser = localStorage.getItem('AIComparisonLoggedInUser');
    if (loggedInUser != null && loggedInUser != undefined) {
      this.userData = JSON.parse(loggedInUser);
      console.log(this.userData);
      if (this.userData != null) {
        this.name = this.userData.LoggedInUser.Name;
        this.profilePic = this.userData.LoggedInUser.ProfilePic;
      }
    }
  }

  ngOnDestroy(): void {
    this.notifierSubscription.unsubscribe();
    this.notifierCompareSubscription.unsubscribe();
  }

  ngOnInit() {
    this.itemsToCompare = 0;
    this.registerLoggedInUser();

    if (this.userData != null) {
      this.name = this.userData.LoggedInUser.Name;
      this.profilePic = this.userData.LoggedInUser.ProfilePic;
    }
    //this.getyouitipwebAppHeader();
    this.loadNotifications();
  }

  fnturnOnNightMode() {}

  goToUserProfile() {
    this.setClass('up');
    this.router.navigate(['/myprofile']);
  }

  getyouitipwebAppHeader(): void {}

  logout() {
    localStorage.removeItem('AIComparisonLoggedInUser');
    location.reload();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ReelsComponent);
  }

  setClass(bclick) {
    switch (bclick) {
      case 'cous':
        this.cousClicked = true;
        this.fbClicked = false;
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = false;
        break;
      case 'fb':
        this.fbClicked = true;
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = false;
        this.cousClicked = false;
        break;
      case 'ca':
        this.cClicked = true;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = false;
        this.fbClicked = false;
        this.cousClicked = false;
        break;
      case 'ab':
        this.cClicked = false;
        this.abClicked = true;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = false;
        this.cousClicked = false;
        this.fbClicked = false;
        break;
      case 'wi':
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = true;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = false;
        this.fbClicked = false;
        this.cousClicked = false;
        break;
      case 're':
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = true;
        this.cousClicked = false;
        this.fbClicked = false;
        break;
      case 'pr':
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = true;
        this.reClicked = false;
        this.cousClicked = false;
        this.fbClicked = false;
        break;
      case 'up':
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = true;
        this.prClicked = false;
        this.reClicked = false;
        this.fbClicked = false;
        this.cousClicked = false;
        break;
    }
  }

  reset() {
    this.cousClicked = false;
    this.fbClicked = false;
    this.cClicked = false;
    this.abClicked = false;
    this.wiClicked = false;
    this.upClicked = false;
    this.prClicked = false;
    this.reClicked = false;
    this.wiClicked = false;
    this.abClicked = false;
  }

  category() {
    this.setClass('ca');
    this.router.navigate(['/categories']);
  }

  feedback() {
    this.setClass('fb');
    this.router.navigate(['/feedback']);
  }

  blogs() {
    this.setClass('up');
    this.router.navigate(['/blogs']);
  }

  contactUs() {
    this.setClass('cous');
    this.router.navigate(['/contactus']);
  }

  aboutus() {
    this.setClass('ab');
    this.router.navigate(['/aboutus']);
  }

  reels() {
    this.setClass('re');
    this.router.navigate(['/reels']);
  }

  pricing() {
    this.setClass('pr');
    this.router.navigate(['/pricing']);
  }

  openTipDialog() {
    this.setClass('wi');
    this.router.navigate(['/whoisusingyouitip']);
  }

  savedTips() {
    this.router.navigate(['/msavedtips']);
  }

  changePassword() {
    this.router.navigate(['/mchangepassword']);
  }

  openCreateDialog() {
    this.compareAIPlatForm();
  }

  loadNotifications() {
    if (this.userData != null) {
      this.tipService
        .getUserNotifications(this.userData.LoggedInUser.UserEmail)
        .subscribe(async (data) => {
          data.Notifications.forEach((element) => {
            this.notificationList.push(element);
          });
        });
    }
  }

  notifierCompareSubscription: Subscription =
    this.dataService.subjectAddToCompareNotifier.subscribe((compareCount) => {
      console.log('coparecount', compareCount);
      if (this.itemsToCompare < 4) {
        if (compareCount != null || compareCount != undefined) {
          this.itemsToCompare = this.itemsToCompare + compareCount;
          this.notificationService.showSuccess('Platform added to compare');
        }
      } else {
        this.notificationService.showError('We can only compare 4 platforms');
      }
    });

  openViewTipDialog(tipId) {
    console.log(tipId);
    localStorage.removeItem('selectedTip');
    localStorage.setItem('notificationTipId', tipId);
    const dialogRef = this.dialog.open(ViewtipComponent);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  compareAIPlatForm() {
    if (this.itemsToCompare < 2) {
      this.notificationService.showError(
        'Please select 2 or more platforms to compare'
      );
      return;
    }
    this.router.navigate(['/compareai']);
  }
}
