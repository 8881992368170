import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-mnotifications',
  templateUrl: './mnotifications.component.html',
  styleUrls: ['./mnotifications.component.scss'],
})
export class MnotificationsComponent implements OnInit {
  pageIndex: number;
  notificationList: any = [];
  userData: any;

  constructor(
    private tipService: TipService,
    private router: Router,
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('Notifications Page');
    this.titleAndMetaService.setMetaTag();
    this.pageIndex = 0;
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);
    var loggedInUser = localStorage.getItem('AIComparisonLoggedInUser');
    if (loggedInUser != null && loggedInUser != undefined) {
      this.userData = JSON.parse(loggedInUser);
      this.loadNotifications();
    }
  }

  loadNotifications() {
    this.dataService.notifyHideFooter('');
    this.tipService
      .getUserNotifications(this.userData.LoggedInUser.UserEmail)
      .subscribe(async (data) => {
        this.dataService.notifyShowFooter('');
        console.log(data.Notifications);
        data.Notifications.forEach((element) => {
          this.notificationList.push(element);
        });
      });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
