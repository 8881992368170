import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-scratchcard',
  templateUrl: './scratchcard.component.html',
  styleUrls: ['./scratchcard.component.scss'],
})
export class ScratchcardComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  panelOpenState = false;
  rewardDetails: any = [];
  offer: any;
  shortDescription: any;
  tclist: any;
  siteUrl: any;
  userData: any;
  profilePic: any;
  showImage: boolean;

  constructor() {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);

    if (this.userData != null) {
      if (this.userData.LoggedInUser.ProfilePic != null) {
        this.profilePic = this.userData.LoggedInUser.ProfilePic;
        console.log(this.profilePic);
        this.showImage = true;
      } else {
        this.showImage = false;
      }
    }
    this.rewardDetails = JSON.parse(localStorage.getItem('reward'));
    console.log(this.rewardDetails);

    this.offer = this.rewardDetails.Offer;
    console.log(this.offer);
    this.shortDescription = this.rewardDetails.ShortDescription;

    this.tclist = this.rewardDetails.TcList;

    this.siteUrl = this.rewardDetails.SiteUrl;
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
