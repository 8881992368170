<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>Login</h2>
  </div>
</section>
<section>
  <div class="Login">
    <div align="center">
      <div id="formContent">
        <div class="fadeIn first">
        </div>
        <form [formGroup]="loginForm" novalidate>
          <input
            type="email"
            class="form-control"
            formControlName="email"
            [(ngModel)]="email"
            [ngClass]="{
              'is-invalid':
                loginForm.get('email').touched && loginForm.get('email').invalid
            }"
            name="login"
            required
            class="fadeIn second"
            placeholder="Email Address"
          />
          <div
            *ngIf="
              loginForm.controls['email'].invalid &&
              (loginForm.controls['email'].dirty ||
                loginForm.controls['email'].touched)
            "
            class="alert alert-danger"
          >
            Email Address is required.
          </div>
          <input
            type="password"
            class="form-control"
            formControlName="password"
            [(ngModel)]="password"
            [ngClass]="{
              'is-invalid':
                loginForm.get('password') && loginForm.get('password')
            }"
            name="login"
            required
            class="fadeIn second"
            placeholder="Password"
          />
          <div
            *ngIf="
              loginForm.controls['password'].invalid &&
              (loginForm.controls['password'].dirty ||
                loginForm.controls['password'].touched)
            "
            class="alert alert-danger"
          >
            Password is required.
          </div>
          <div align="center" style="width: 100%">
            <!-- <re-captcha
              class="g-recaptcha"
              (resolved)="addTokenLog('Normal mode resolved', $event)"
            ></re-captcha> -->
          </div>
          <br />
          <button
            style="width: 120px; font-size: 16px"
            type="submit"
            (click)="executeAction('login')"
            [disabled]="!loginForm.valid || !token"
            class="btn btn-primary btn-lg mb-3 fadeIn fourth"
          >
            Log In
          </button>

          <div id="formFooter">
            <button
              style="width: 120px; font-size: 16px"
              routerLink="/register"
              type="button"
              class="btn btn-primary btn-lg"
            >
              Register</button
            ><br />
            <button
              routerLink="/mchangepassword"
              style="margin-top: 5px; /* width: 152px; */ font-size: 16px"
              type="button"
              class="btn btn-primary btn-lg"
            >
              Forgot Password ?
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
