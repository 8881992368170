import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TipService } from 'src/app/sharedService/tip.service';

@Component({
  selector: 'app-editcomment',
  templateUrl: './editcomment.component.html',
  styleUrls: ['./editcomment.component.scss'],
})
export class EditcommentComponent implements OnInit {
  comment: any;
  data: any;
  description: any;
  maxdesclength: number;
  currentdesclength = 0;
  disableButton: boolean;
  constructor(private tipService: TipService, private router: Router) {
    this.disableButton = false;
  }

  ngOnInit(): void {
    this.currentdesclength = 0;
    this.maxdesclength = 500;
    this.comment = JSON.parse(localStorage.getItem('editComment'));
    console.log(this.comment);
    this.descChanged();
  }

  descChanged() {
    this.description = this.comment.Description.trim();

    console.log(this.description);
    console.log(this.maxdesclength);

    if (this.description.length == this.maxdesclength) {
      this.comment.Description = this.comment.Description.substring(
        0,
        this.comment.Description.length
      );
    }

    if (this.currentdesclength <= this.maxdesclength)
      this.currentdesclength = this.comment.Description.length;

    this.setIsDisable();
  }

  setIsDisable() {
    console.log('setIsDisable()');
    console.log(this.currentdesclength);
    if (this.currentdesclength > 0) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  async addTip() {
    console.log(this.comment);

    this.tipService.postTipComment(this.comment).subscribe(async (data) => {
      console.log(data);
      //this.noResultReturned = false;
      this.description = '';
      this.router.navigate(['/home']);
    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
