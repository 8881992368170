import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { TipService } from 'src/app/sharedService/tip.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  constructor(
    private activateRouter: ActivatedRoute,
    private tipService: TipService,
    private titleAndMetaService: TitleAndMetaService
  ) {}

  ngOnInit(): void {
    this.activateRouter.paramMap.subscribe((params) => {
      console.log(params);
      var title = params.get('title');
      console.log(title);
      this.titleAndMetaService.setTitle('blog - ' + title);
      if (title == null) {
        console.log('changes for blog list');
        this.blog = JSON.parse(localStorage.getItem('selectedBlog'));
        console.log(this.blog);
      } else {
        this.getTipDetails(title);
      }
    });
  }

  onRightClick($event) {}
  loadMoreblogs() {}

  getTipDetails(title) {
    this.noResultReturned = true;
    this.tipService.getBlogByTitle(title).subscribe(async (data) => {
      this.noResultReturned = false;
      localStorage.setItem('selectedBlog', JSON.stringify(this.blog));
      this.blog = data.BlogModel;
      console.log(this.blog);
      localStorage.setItem('selectedBlog', JSON.stringify(this.blog));
    });
  }
}

//v1/BlogController/getblogByTitle
