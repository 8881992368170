<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>Blogs on AI</h2>
  </div>
</section>
<section class="bloglistnewdesign">
  <div class="Login">
    <div class="handler" align="center">
      <div
        *ngIf="blogs?.length > 0"
        class="scroll user-pro"
        class="card-handler"
        (scrolled)="onScrollDown()"
        class="mobileoutoheight newdesigntile"
      >
        <div class="row homeblog">
          <div class="card-handler">
            <div class="card-wrap" *ngFor="let blog of blogs">
              <div [style.backgroundImage]="blog.CoverImageUrl"
                (click)="viewblog(blog)"
                style="cursor: pointer;"
                class="image-wrap"
              >
                <img
                  *ngIf="blog.ShowImage && blog.Logo_Url"
                  (click)="viewblog(blog)"
                  class="img-fluid"
                  src="{{ blog.Logo_Url }}"
                  alt="Card image cap"
                  style="cursor: pointer"
                />

                <img
                  *ngIf="!blog.ShowImage && blog.Logo_Url"
                  (click)="viewblog(blog)"
                  class="img-fluid"
                  src="../../../../assets/img/logo-black-new.png"
                  alt="Card image cap"
                  style="cursor: pointer"
                />
                <!-- <h1
                  style="cursor: pointer"
                  (click)="viewblog(blog)"
                  (click)="viewblog(blog)"
                  *ngIf="!blog.Url"
                >
                  {{ blog.Title }}
                </h1> -->
              </div>
              <div class="text-wrap">
                <h1
                  (click)="viewblog(blog)"
                  (click)="viewblog(blog)"
                  *ngIf="blog.Url"
                >
                  {{ blog.Title }}
                </h1>
                <div class="user-group usergroupdesktop">
                 
                  <!-- <div class="days">
                    <p>
                      <span class="txt"
                        ><i class="fas fa-clock" title="I like it"></i
                        >{{ blog.Timestamp | dateAgo }}
                      </span>
                    </p>
                  </div> -->
                </div>

                <p (click)="viewblog(blog)">{{ blog.Description }}</p>

                <div class="bottom-fix">
                  <div class="days">
                    <p>
                      <span class="txt">
                        <p>{{ blog.Timestamp }} | {{ timeToRead }} <span><i class="fas fa-clock" title="I like it"></i
                          >{{ blog.TimeToReadInMinutes }} read</span></p></span
                      >
                    </p>
                  </div>
                  <h1
                  style="cursor: pointer"
                  (click)="viewblog(blog)"
                  (click)="viewblog(blog)"
                  *ngIf="!blog.Url"
                >
                  {{ blog.Title }}
                </h1>
                  <div class="button-wrap">
                    <div class="cat-name">
                      <a (click)="viewblog(blog)" style="cursor: pointer"
                        >Learn more..</a
                      >
                    </div>
                    <!-- <div class="days">
                      <p>
                        <span class="txt">
                          <p>{{ blog.Timestamp }} | {{ timeToRead }} <span><i class="fas fa-clock" title="I like it"></i
                            >{{ blog.TimeToReadInMinutes }} read</span></p></span
                        >
                      </p>
                    </div> -->
                  </div>
                </div>
                <div *ngIf="blog.Animateblog" class="anisecondheart">
                  <div class="bubbling-heart">
                    <div><i class="fa fa-heart fa-5x"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="load-more">
        <button *ngIf="!loadMore" (click)="loadMoreTips()">
          Load more
        </button>
        <h5 class="Usermsg" *ngIf="loadMore">
          Thats it for now :)
        </h5>
      </div>

      <div *ngIf="noResultReturned" class="img-div-left">
        <img
          alt="loading spinner"
          onContextMenu="return false;"
          class="spinner-global img-fluid"
          style="width: 100px; height: 100px; display: block; margin: 0px auto"
          src="../../../../assets/img/Spinner.gif"
        />
      </div>
    </div>
  </div>
</section>
