import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss']
})
export class AuthorComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  authorName: any;
  timeToRead:any;
  authorContact: any;
  @Input() rowKey: string;

  constructor() {}
  ngOnInit(): void {
    console.log('changes for blog list');
    
    this.blog = JSON.parse(localStorage.getItem('selectedBlog'));
    console.log(this.blog);
    console.log(
      this.blog.AuthorModel.filter((b) => b.RowKey == this.rowKey)[0]
        ?.AuthorName
    );
    this.authorName = this.blog.AuthorModel.filter(
      (b) => b.RowKey == this.rowKey
    )[0]?.AuthorName;
    this.authorContact = this.blog.AuthorModel.filter(
      (b) => b.RowKey == this.rowKey
    )[0]?.Contact;
    this.timeToRead=this.blog.TimeToReadInMinutes;

  }
  onRightClick($event) {}
  loadMoreblogs() {}
}
