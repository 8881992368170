import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bullet',
  templateUrl: './bullet.component.html',
  styleUrls: ['./bullet.component.scss'],
})
export class BulletComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  bulletText: any;
  @Input() rowKey: string;


  constructor() {}
  ngOnInit(): void {
    this.blog = JSON.parse(localStorage.getItem('selectedBlog'));
    this.bulletText = this.blog.BulletsModel.filter(
      (b) => b.RowKey == this.rowKey
    )[0]?.BulletText;

  }
  onRightClick($event) {}
  loadMoreblogs() {}

  
}


