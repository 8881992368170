import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { CategoryplatformsComponent } from '../categoryplatforms/categoryplatforms.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  userData: any;
  userEmail: any;
  noResultReturned: boolean;
  categories: any = [];
  categoryId: any;
  tips: any = [];
  tipliked: boolean;
  loadMore: boolean;
  pageSize: any;
  searchinput: string;
  pageIndex: number;
  items: any = [];
  noMoreRecordsAvailable: any;
  platform1: string;
  platform2: string;
  platform3: string;
  platform4: string;

  itemsToCompare: string;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService,
    private tipHelperService: TipHelperService,
    private notificationService: NotificationService
  ) {
    this.loadMore = true;
  }

  ngOnInit(): void {
    this.itemsToCompare = '';
    this.titleAndMetaService.setTitle('Categories Page');
    this.titleAndMetaService.setMetaTag();
    this.pageSize = localStorage.getItem('AppPageSize');
    console.log(this.pageSize);
    localStorage.removeItem('aihubcategorypageIndex');
    localStorage.setItem('aihubcategorypageIndex', '0');

    localStorage.removeItem('youtipcategorypagesearchIndex');
    localStorage.setItem('youtipcategorypagesearchIndex', '0');

    localStorage.removeItem('youtipcategorypageTipIndex');
    localStorage.setItem('youtipcategorypageTipIndex', '0');
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);

    if (this.userData != null) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
    } else {
      this.userEmail = '';
    }
    this.getCategories('0');
  }

  search() {
    this.noResultReturned = false;
    console.log(this.searchinput);
    if (this.searchinput != '' && this.searchinput != undefined) {
      this.pageIndex = 0;
      localStorage.removeItem('youtipcategorypagesearchIndex');
      localStorage.setItem('youtipcategorypagesearchIndex', '0');
      this.tips = [];
      this.loadSearchTips(this.pageIndex, undefined);
    } else {
      this.tips = [];
      localStorage.removeItem('aihubcategorypageIndex');
      localStorage.setItem('aihubcategorypageIndex', '0');
      ///this.loadTips('0');
    }
  }

  async loadSearchTips(pageIndex, event) {
    console.log(this.categoryId);
    console.log(this.searchinput);
    console.log(this.userEmail);
    this.noResultReturned = true;
    this.tipService
      .searchCategoryTips(
        pageIndex,
        this.categoryId,
        this.searchinput,
        this.userEmail
      )
      .subscribe(async (data) => {
        this.noResultReturned = false;
        console.log(data.Tips);
        data.Tips.forEach((element) => {
          this.tips.push(element);
        });

        this.setLoadMore(data.Tips);
      });
  }

  categoryClicked(category) {
    this.close();
    console.log(category.CategoryName);
    // if (!category.isChecked) {
    var tips = this.tips.filter((t) => t.Cateogry == category.CategoryName);
    console.log(this.tips.length);
    console.log(this.tips);
    if (tips.length == 0) {
      console.log(category.isChecked);
      this.categoryId = category.CategoryName;
      this.loadTips('0');
    } else {
      localStorage.removeItem('categoryplatformsComponent');
      localStorage.setItem('categoryplatformsComponent', JSON.stringify(tips));
      const dialogRef = this.dialog.open(CategoryplatformsComponent, {
        panelClass: 'dialogueboxcategoryplatforms',
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }
    // } else if (category.isChecked) {
    //   this.tips = this.tips.filter((t) => t.Cateogry != category.CategoryName);
    // }
  }

  getCategories(pageIndex) {
    this.dataService.notifyHideFooter('');
    this.noResultReturned = true;
    this.tipService.getCategories(pageIndex).subscribe((res) => {
      console.log(res.Categories);
      console.log(res.Categories.length);
      if (res.Categories.length == 0) {
        this.noResultReturned = false;
        this.noMoreRecordsAvailable = true;
        return;
      }

      this.dataService.notifyShowFooter('');

      res.Categories.forEach((element) => {
        this.categories.push({
          isChecked: false,
          CategoryName: element.CategoryName,
        });
      });

      this.setLoadMore(res.Categories);
      // console.log(this.categories[0]);
      this.categoryId = res.Categories[0].CategoryName;
      this.categories[0].isChecked = true;
      // this.categories = res.Categories;
      //this.getTips('0');
      //this.loadTips('0');
      this.noResultReturned = false;
    });
  }

  getTips(pageIndex) {
    this.tipService.getTips(pageIndex, this.userEmail).subscribe((res) => {
      res.Tips.forEach((element) => {
        var platForm = this.tips.filter((t) => t.Title == element.Title)[0];

        console.log(platForm);

        if (platForm == undefined || platForm == '') {
          this.tips.push(element);
        }
      });

      console.log(this.tips);
      console.log(this.tips);
      this.noResultReturned = false;
    });
  }

  tipByCategory(tip) {
    localStorage.setItem(
      'tipCategory',
      JSON.stringify({
        categoryName: tip.CategoryName,
        categoryId: tip.CategoryId,
      })
    );
    this.router.navigate(['/tipbycategory']);

    console.log(tip);
  }

  onScrollDown() {
    //this.spinner.show();
    console.log('scrolled');
    //this.noResultReturned = true;
    this.delay(1000).then(() => {
      console.log('ondemand');
      this.delayedServiceCall();
    });
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem('aihubcategorypageIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('aihubcategorypageIndex', pageIndex);
    console.log(pageIndex);
    this.getCategories(pageIndex);
  }

  loadNextPageTips() {
    var pageIndex = localStorage.getItem('youtipcategorypageTipIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('youtipcategorypageTipIndex', pageIndex);
    console.log(pageIndex);
    //this.loadTips(pageIndex);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  AddToCompare(tip) {
    console.log(tip);
    // console.log(this.itemsToCompare);
    // console.log(this.itemsToCompare.length);

    if (this.items.length == 4) {
      this.notificationService.showError('We can only compare 4 platforms');
      return;
    }

    if (this.itemsToCompare.length > 1) {
      var item = this.itemsToCompare.includes(tip.RowKey);
      if (item) return;
    }

    if (this.itemsToCompare.length > 1) {
      this.itemsToCompare = this.itemsToCompare + tip.RowKey + ';';
    } else {
      this.itemsToCompare = tip.RowKey + ';';
      this.items.push(this.itemsToCompare);
    }

    if (this.itemsToCompare.length > 0)
      this.items = this.itemsToCompare.split(';');

    this.items.pop('');

    console.log(this.items);

    if (this.items.length == 1) {
      this.platform1 = tip.Platform_Name;
    }
    if (this.items.length == 2) {
      this.platform2 = tip.Platform_Name;
    }
    if (this.items.length == 3) {
      this.platform3 = tip.Platform_Name;
    }
    if (this.items.length == 4) {
      this.platform4 = tip.Platform_Name;
    }

    console.log(this.items.length);
    console.log(this.itemsToCompare);
    console.log(
      this.itemsToCompare.substring(0, this.itemsToCompare.length - 1)
    );

    localStorage.setItem(
      'itemsToCompare',
      this.itemsToCompare.substring(0, this.itemsToCompare.length - 1)
    );
    this.dataService.notifyAboutAddToCompare(1);
  }

  setLoadMore(tips) {
    console.log(tips.length);
    console.log(this.pageSize);
    this.loadMore = !(tips.length < this.pageSize);
  }

  close() {
    this.platform1 = '';
    this.platform2 = '';
    this.platform3 = '';
    this.platform4 = '';

    this.items = [];
    this.itemsToCompare = '';
    console.log(this.items.length);
    console.log(this.itemsToCompare);

    localStorage.removeItem('itemsToCompare');
    this.dataService.notifyAboutResetToCompare();
  }

  loadTips(pageIndex) {
    console.log(this.categoryId);
    this.noResultReturned = true;
    this.tipService
      .getCategoryPlatforms(pageIndex, this.categoryId, this.userEmail)
      .subscribe(async (data) => {
        console.log(data);
        this.noResultReturned = false;

        data.AIHubRecords.forEach((element) => {
          var platForm = this.tips.filter((t) => t.Title == element.Title)[0];

          console.log(platForm);

          if (platForm == undefined || platForm == '') {
            this.tips.push(element);
          }
        });

        this.setLoadMore(data.AIHubRecords);
        console.log(this.tips);

        var tips = this.tips.filter((t) => t.Cateogry == this.categoryId);
        console.log(this.tips.length);
        console.log(this.tips);
        localStorage.removeItem('categoryplatformsComponent');
        localStorage.setItem(
          'categoryplatformsComponent',
          JSON.stringify(tips)
        );
        const dialogRef = this.dialog.open(CategoryplatformsComponent, {
          panelClass: 'dialogueboxcategoryplatforms',
        });
        dialogRef.afterClosed().subscribe((result) => {
          console.log(`Dialog result: ${result}`);
        });
      });
  }

  async likeTip(tip) {
    if (this.userData != null) {
      var rowKey = this.tipHelperService.getRowKey(tip);
      this.tipService
        .likeTip(rowKey, this.userData.LoggedInUser.UserEmail, tip.LikeCount)
        .subscribe(async (data) => {
          console.log(data.IsTipLiked);
          if (data.IsTipLiked) {
            this.notificationService.showAnimation(this.tips, tip);
            tip.LikeCount++;
            this.tipliked = true;
            tip.IsTipLiked = true;
            setTimeout(() => {
              this.tipliked = false;
            }, 2000);
          } else {
            tip.LikeCount--;
            tip.IsTipLiked = false;
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveTip(tip) {
    console.log(tip);
    if (this.userData != null) {
      if (this.userData != null) {
        var rowKey = this.tipHelperService.getRowKey(tip);
        this.tipService
          .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
          .subscribe(async (data) => {
            console.log(data);
            tip.IsTipSaved = data.IsTipSaved;
            if (data.IsTipSaved) {
              this.notificationService.showAnimation(this.tips, tip);
              tip.SaveCount++;
              this.tipliked = true;
              setTimeout(() => {
                this.tipliked = false;
              }, 2000);
            } else {
              tip.SaveCount--;
            }
          });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  viewTip(tip) {
    if (tip.Platform_Url == null) return;

    console.log(tip);
    const target = '_self';
    const refLink = window.open(tip.Platform_Url);
  }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        console.log('myprofile');
        this.router.navigate(['myprofile/editprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        console.log('otheruserprofile');
        this.router.navigate(['otheruserprofile/otherusertips']);
      }
    }
  }

  viewMobileUserProfile(userEmail) {
    console.log('inviewmobileuser');
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        this.router.navigate(['/mytips']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/mothertips']);
      }
    }
  }

  loadMoreTips() {
    this.delayedServiceCall();
  }
}
