<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
      <h2>Membership Plans that helps you to grow parallelly</h2>
    </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2 *ngIf="blog && blog.BlogMetadata && blog.BlogMetadata.length > 0">
      {{ blog.Title }}
    </h2>
  </div>
</section>
<section style="margin-bottom: 50px">
  <div class="Login">
   
    <div *ngIf="blog && blog.BlogMetadata" class="handler1" align="center">
      <div
        *ngIf="blog && blog.BlogMetadata.length > 0"
        class="scroll user-pro"
        class="card-handler1"
        (scrolled)="onScrollDown()"
        class="mobileoutoheight newdesigntile"
      >
        <div class="row homeblog">
          <div *ngIf="blog.HashtagModels" class="handler1" align="center">
            <ul class="hashcategory">
              <li *ngFor="let hashTag of blog.HashtagModels">
                {{ hashTag.HashTag }}
              </li>
            </ul>
          </div>
          <div class="card-handler">
            <div
              class="handler"
              *ngFor="let blog of blog.BlogMetadata"
              align="center"
            >
              <div *ngIf="blog.ElementType == 'Author'" align="center">
                <app-author [rowKey]="blog.ElementId"></app-author>
              </div>
              <div *ngIf="blog.ElementType == 'Bullet'" align="center">
                <app-bullet [rowKey]="blog.ElementId"></app-bullet>
              </div>
              <div *ngIf="blog.ElementType == 'Paragraph'" align="center">
                <app-paragraph [rowKey]="blog.ElementId"></app-paragraph>
              </div>
              <div *ngIf="blog.ElementType == 'SubTitle'" align="center">
                <app-subtitle [rowKey]="blog.ElementId"></app-subtitle>
              </div>
              <div *ngIf="blog.ElementType == 'Images'" align="center">
                <app-images [rowKey]="blog.ElementId"></app-images>
              </div>
              <div *ngIf="blog.ElementType == 'Videos'" align="center">
                <app-videos [rowKey]="blog.ElementId"></app-videos>
              </div>
              <div *ngIf="blog.ElementType == 'Table'" align="center">
                <app-blogtable [TableId]="blog.ElementId"></app-blogtable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="noResultReturned" class="img-div-left">
      <img
        alt="loading spinner"
        onContextMenu="return false;"
        class="spinner-global img-fluid"
        style="width: 100px; height: 100px; display: block; margin: 0px auto"
        src="../../../../assets/img/Spinner.gif"
      />
    </div>
  </div>
</section>
