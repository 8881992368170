import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class TitleAndMetaService {
  constructor(
    public http: HttpClient,
    private meta: Meta,
    private title: Title
  ) {}

  setTitle(title) {
    console.log(title);
    this.title.setTitle(title);
  }

  setMetaTag() {
    this.meta.addTags([
      {
        name: 'title',
        content: 'AI Comparison - Helps to compare AI Platforms',
      },
      {
        name: 'description',
        content:
          'AI Comparison is a platform helps compare AI platforms and always helps to make the right decisions',
      },
    ]);
  }
}
