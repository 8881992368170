import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogtable',
  templateUrl: './blogtable.component.html',
  styleUrls: ['./blogtable.component.scss'],
})
export class BlogtableComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  tableModels: any;
  columns: any = [];
  values: any = [];
  displayedColumns: string[] = [];
  columnNames: string[] = [];
  table: any;
  @Input() TableId: string;
  dataSource: any[] = [];
  tableData: any[] = [];

  constructor() {}
  ngOnInit(): void {
    this.blog = JSON.parse(localStorage.getItem('selectedBlog'));

    this.dataSource = this.blog.BlogTablesModels.filter(
      (b) => b.TableId == this.TableId
    )[0].TableColumnValues;
    this.columnNames = this.blog.BlogTablesModels.filter(
      (b) => b.TableId == this.TableId
    )[0].ColumnNames;
    // this.displayedColumns = this.dataSource.map((item) => item.ColumnName);
    // console.log(this.displayedColumns);
    console.log(this.columnNames);
    console.log(this.dataSource);
  }
}
