import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss'],
})
export class EditprofileComponent implements OnInit {
  name: string;
  email: string;
  public registerForm: UntypedFormGroup;
  registeredUser: any;
  userName: any;
  userEmailTaken: boolean;
  isCapsLockPressed: any;
  isUserNameTaken: boolean;
  isTermsAndConditionChecked: boolean;
  noResultReturned: boolean;
  bio: string;
  phone: number;
  showImage: boolean;
  lastImage: string;
  userData: any;
  dob: any;
  profession: any;
  selectedFile: File;
  profilePicture: string;
  fileData: File = null;
  followerCount: string;
  followingCount: string;
  tipCount: string;
  isPhoneValid: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tipService: TipService,
    private router: Router,
    private titleAndMetaService: TitleAndMetaService,
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
    this.isTermsAndConditionChecked = true;
  }

  ngOnInit(): void {
    localStorage.removeItem('OtherUserFollowerTipsEmail');
    this.titleAndMetaService.setTitle('Edit Profile Page');
    this.titleAndMetaService.setMetaTag();
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);
    this.registerForm = this.formBuilder.group({
      name: new UntypedFormControl('', Validators.required),
      userName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      phone: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(10),
        this.invalidMobile('phone'),
      ]),
      dob: new UntypedFormControl('', Validators.required),
      profession: new UntypedFormControl('', Validators.required),
    });

    console.log('ionViewWillEnter');
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    if (this.userData != null && this.userData != undefined) {
      this.followerCount = this.userData.LoggedInUser.FollowerCount;
      this.followingCount = this.userData.LoggedInUser.FollowingCount;
      this.tipCount = this.userData.LoggedInUser.TipCount;
      this.name = this.userData.LoggedInUser.Name;
      this.email = this.userData.LoggedInUser.UserEmail;
      this.bio = this.userData.LoggedInUser.UserBio;
      this.userName = this.userData.LoggedInUser.UserName;
      this.profession = this.userData.LoggedInUser.Proffession;
      this.dob = this.userData.LoggedInUser.DateOfBirth;
      this.phone = this.userData.LoggedInUser.Phone;
      console.log(this.userData);
      if (this.userData.LoggedInUser.ProfilePic != null) {
        this.profilePicture = this.userData.LoggedInUser.ProfilePic;
        this.showImage = true;
        console.log(this.userData);
        console.log(this.userData.LoggedInUser.ProfilePic);
      } else {
        this.showImage = false;
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  setUserData() {
    if (this.userData != null && this.userData != undefined) {
      this.userData.LoggedInUser.Name = this.name;
      this.userData.LoggedInUser.UserEmail = this.email;
      this.userData.LoggedInUser.UserBio = this.bio;
      this.userData.LoggedInUser.UserName = this.userName;
      this.userData.LoggedInUser.Proffession = this.profession;
      this.userData.LoggedInUser.DateOfBirth = this.dob;
      this.userData.LoggedInUser.FollowerCount = this.followerCount;
      this.userData.LoggedInUser.FollowingCount = this.followingCount;
      this.userData.LoggedInUser.TipCount = this.tipCount;
      this.userData.LoggedInUser.Phone = this.phone;

      console.log(this.userData);
      if (this.profilePicture != null) {
        this.userData.LoggedInUser.ProfilePic = this.profilePicture;
        this.showImage = true;
        console.log(this.userData);
        console.log(this.userData.LoggedInUser.ProfilePic);
      } else {
        this.showImage = false;
      }
    }
  }

  register() {
    this.noResultReturned = true;
    let customerModel = {
      Name: this.name,
      UserName: this.userName,
      UserEmail: this.email,
      UserBio: this.bio,
      ProfilePic: this.profilePicture,
      RowKey: this.userName,
      PartitionKey: this.email,
      Proffession: this.profession,
      DateOfBirth: this.dob,
      FollowerCount: this.userData.LoggedInUser.FollowerCount,
      FollowingCount: this.userData.LoggedInUser.FollowingCount,
      TipCount: this.userData.LoggedInUser.TipCount,
      Phone: this.phone,
    };

    console.log(customerModel);

    this.tipService.updateUser(customerModel).subscribe(async (data) => {
      this.noResultReturned = false;
      console.log(data.UserData);
      if (data != null) {
        // var AIComparisonLoggedInUser = JSON.parse(
        //   localStorage.getItem('AIComparisonLoggedInUser')
        // );
        this.setUserData();
        localStorage.setItem(
          'AIComparisonLoggedInUser',
          JSON.stringify(this.userData)
        );
        console.log(this.userData);
        this.notificationService.showSuccess('Details updated successfully!');
        //this.router.navigate(['/userprofile']);
      } else {
        console.log('Not able register user');
      }
    });
  }

  IsUserEmailTaken() {
    if (this.email != null && this.email != '' && this.email != undefined) {
      this.noResultReturned = true;
      console.log(this.email);
      this.userEmailTaken = false;
      this.tipService.isUserEmailTaken(this.email).subscribe((data) => {
        this.noResultReturned = false;
        console.log(data);
        if (data.IsUserEmailTaken) {
          if (this.userData.UserEmail != this.email) {
            this.userEmailTaken = true;
          }
        } else {
          this.userEmailTaken = false;
        }
      });
    }
  }

  IsUserNameTaken() {
    if (
      this.userName != null &&
      this.userName != '' &&
      this.userName != undefined
    ) {
      console.log(this.userName);
      this.isUserNameTaken = false;
      this.tipService.isUserNameTaken(this.userName).subscribe((data) => {
        console.log(data);
        if (data.IsUserNameTaken) {
          if (this.userData.UserName != this.userName) {
            this.isUserNameTaken = true;
          }
        } else {
          this.isUserNameTaken = false;
        }
      });
    }
  }

  private catchErrors() {
    return async (res: Response) => {
      alert(res.status);

      if (
        res.status === 401 ||
        res.status === 403 ||
        res.status === 501 ||
        res.status === 500
      ) {
        //handle authorization errors
        //in this example I am navigating to login.
      }
      return Observable.throw(res);
    };
  }

  validateUserName(field_name): ValidatorFn {
    if (!this.isCapsLockPressed) {
      return (control: AbstractControl): { [key: string]: any } => {
        let input = control.value;
        var count = 0;
        let regExp = /^[a-zA-Z0-9-_.]+$/;
        let isValid = regExp.test(input);
        let alphabets = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z';
        let upperCasealphabets =
          'A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z';

        if (isValid) {
          if (
            input.length == 1 &&
            !alphabets.toLowerCase().includes(input.toLowerCase())
          ) {
            isValid = false;
          }
        }

        if (isValid) {
          if (input != '' && input != undefined) {
            count = (input.match(/\./g) || []).length;
            if (count > 1) {
              isValid = false;
            }
          }
        }

        if (isValid) {
          if (input != '' && input != undefined) {
            var lastLetter = input.charAt(input.length - 1);
            if (lastLetter == '.') {
              isValid = false;
            }
          }

          input = input.toLowerCase();
        }

        if (isValid) {
          var numbers = /^[0-9]+$/;
          if (input.match(numbers)) {
            isValid = false;
          }
        }
        this.userName = this.userName.toLocaleLowerCase();
        if (!isValid) return { validateUserName: { isValid } };
        else return null;
      };
    } else {
      this.isCapsLockPressed = false;
    }
  }

  ngAfterViewInit() {}

  onFileChanged(event) {
    console.log(event);
    this.selectedFile = event.target.files[0];
    this.profilePicture = this.selectedFile.name;
    console.log(this.profilePicture);
  }

  selectFiles(event) {
    if (event.target.files) {
      this.noResultReturned = true;
      //this.spinner.show();
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append('file', this.fileData);
      this.http
        .post(
          'https://swashmarket.azurewebsites.net/v1/ImageController/PostUploadImage',
          formData
        )
        .subscribe((res) => {
          // this.profilePicture =
          //   'https://timitimsorg-staging.azurewebsites.net/UserImages/' +
          //   this.fileData.name;
          // this.spinner.hide();
          this.noResultReturned = false;
          var mediaUploadStatus = res as any;
          console.log(mediaUploadStatus);
          this.noResultReturned = false;
          //this.spinner.hide();
          if (mediaUploadStatus.MediaUploadStatus != null) {
            this.profilePicture =
              'https://swashstorage.blob.core.windows.net/products/' +
              mediaUploadStatus.MediaUploadStatus.FileName;
          } else {
            alert(mediaUploadStatus.MediaUploadStatus.Message);
          }
        });
    }
  }

  invalidMobile(field_name): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let input = control.value;

      let regExp = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;

      var result = regExp.test(control.value);
      const isValid = (this.isPhoneValid = result);
      if (!isValid) return { invalidMobile: { isValid } };
      else return null;
    };
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
