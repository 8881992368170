import { MyrewardsComponent } from './../myrewards/myrewards.component';
import { Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { TipService } from 'src/app/sharedService/tip.service';
import { NotificationService } from 'src/app/sharedService/notification.service';

@Component({
  selector: 'app-createtip',
  templateUrl: './createtip.component.html',
  styleUrls: ['./createtip.component.scss'],
})
export class CreatetipComponent implements OnInit {
  taskList: any = [];
  categories: any = [];
  taskName: any;
  taskDescription: any;
  category: string;
  isEmailTaken: any;
  title: string;
  description: string;
  public registerForm: UntypedFormGroup;
  lastImage: string; //= //'http://vststrackerapi.azurewebsites.net/userimage/Pocathon.Png.png';
  videoUrl: string; //= //'http://vststrackerapi.azurewebsites.net/userimage/Pocathon.Png.png';
  videoPosterUrl: string;
  imageURI: any;
  userData: any;
  noResultReturned: boolean;
  maxtitlelenght: number;
  maxCategoryLength: number;
  maxdesclenght: number;
  currenttitlelenght: number;
  disableButton: boolean;
  currentdesclenght: number;
  currentCategorylenght: number;
  searchCategoryPageIndex: number;
  isSearchClicked: boolean;
  pageIndex: number;
  isMouseDown: boolean;
  isAddItem: boolean;
  fileData: File = null;

  url = 'https://play.google.com/store/apps/details?id=io.ionic.youitip';
  progress: number;
  publishedFor: any;
  constructor(
    private router: Router,
    private titleAndMetaService: TitleAndMetaService,
    private formBuilder: UntypedFormBuilder,
    private tipService: TipService,
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
    this.title = '';
    this.description = '';
    this.category = '';
    this.noResultReturned = false;
    this.disableButton = true;
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);

    if (this.userData == null) this.router.navigate(['/login']);
    this.titleAndMetaService.setTitle('Create Tip Page');
    this.titleAndMetaService.setMetaTag();

    this.maxtitlelenght = 250;
    this.maxCategoryLength = 100;
    this.maxdesclenght = 500;
    this.currentCategorylenght = 0;
    this.currentdesclenght = 0;
    this.currenttitlelenght = 0;

    this.registerForm = this.formBuilder.group({
      title: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', [Validators.required]),
      category: new UntypedFormControl('', [Validators.required]),
      publishedFor: new UntypedFormControl('', [Validators.required]),
    });
  }

  goToStore() {
    window.open(this.url, '_blank');
  }

  titleChanged() {
    console.log('title changed');
    if (this.title == null || this.title == undefined) return;

    this.title = this.title.trim();

    if (this.title.length == this.maxtitlelenght) {
      this.title = this.title.substring(0, this.title.length);
    }

    if (this.currenttitlelenght <= this.maxtitlelenght) {
      this.currenttitlelenght = this.title.length;
    }

    this.setIsDisable();
  }

  setIsDisable() {
    console.log(this.currentCategorylenght);
    console.log(this.currenttitlelenght);
    if (this.currenttitlelenght > 0 && this.currentCategorylenght > 0) {
      this.disableButton = false;
      console.log(this.disableButton);
    } else {
      console.log(this.disableButton);
      this.disableButton = true;
    }
  }

  descChanged() {
    console.log('description changed');
    if (this.description == null || this.description == undefined) return;

    this.description = this.description.trim();

    if (this.description.length == this.maxdesclenght) {
      this.description = this.description.substring(0, this.description.length);
    }
    if (this.currentdesclenght <= this.maxdesclenght) {
      this.currentdesclenght = this.description.length;
    }

    this.setIsDisable();
  }

  categoryChanged() {
    console.log('category changed');
    console.log(this.category);
    if (
      this.category == '' ||
      this.category == null ||
      this.category == undefined
    ) {
      this.setIsDisable();
      return;
    }
    this.category = this.category.trim();

    if (this.category.length == this.maxCategoryLength) {
      this.category = this.category.substring(0, this.category.length);
    }

    if (this.currentCategorylenght <= this.maxCategoryLength) {
      this.currentCategorylenght = this.category.length;
    }

    this.delay(100).then(() => {
      if (!this.isAddItem) {
        console.log('ondemand');
        this.categories = [];
        this.searchCategory();
      } else {
        this.isAddItem = false;
      }
    });

    this.setIsDisable();
  }

  searchCategory() {
    this.noResultReturned = true;

    this.tipService
      .searchCategories('0', this.category)
      .subscribe(async (data) => {
        //        await loading.dismiss();
        console.log(data.Categories);
        if (data.Categories.length <= 0) {
          // setTimeout(() => {
          //   this.noResultReturned = false;
          // }, 500);
        } else {
          data.Categories.forEach((element) => {
            this.categories.push(element.Name);
          });

          //          this.categories = data.Categories;
          console.log(this.categories);
        }
        this.noResultReturned = false;
      });
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  mousedowne() {
    this.isMouseDown = true;
  }

  removeFocus() {
    console.log('remove focus');
    if (!this.isMouseDown) {
      this.categories = [];
    }
    this.isMouseDown = false;
  }
  add(category) {
    this.category = category.Name;
    this.categories = [];
    this.isMouseDown = false;
    this.isAddItem = true;
  }

  async addTip() {
    if (
      this.title == undefined ||
      this.title == '' ||
      this.title == null
    ) {
      this.notificationService.showError('Please enter Tip title');
      return;
    }

    if (
      this.category == undefined ||
      this.category == '' ||
      this.category == null
    ) {
      this.notificationService.showError('Please enter Category');
      return;
    }

    if (
      this.publishedFor == undefined ||
      this.publishedFor == '' ||
      this.publishedFor == null
    ) {
      this.notificationService.showError('Please select Who can see your Tip?');
      return;
    }

    console.log('asdsadasda00');

    var vid = document.getElementById('myVideo') as any;
    console.log(vid);
    if (vid != null && vid != undefined) {
      var duration = Math.round(vid.duration);

      if (duration > 30) {
        alert('Only videos less than 30sec duration are allowed');
        return;
      }
    }

    console.log('asdsadasda00');

    this.noResultReturned = true;

    let tipModel = {
      Title: this.title,
      Description: this.description,
      Url:
        this.lastImage != null && this.lastImage != ''
          ? this.lastImage
          : this.videoUrl,
      UserName: this.userData.LoggedInUser.UserName,
      UserEmail: this.userData.LoggedInUser.UserEmail,
      CategoryName: this.category,
      ProfilePic: this.userData.LoggedInUser.ProfilePic,
      VideoPosterUrl: this.videoPosterUrl,
      Publishedfor: this.publishedFor,
    };

    console.log(tipModel);
    console.log('asdsadasda00');

    this.tipService.cerateTip(tipModel).subscribe(async (data) => {
      console.log('onDidDismiss');
      this.noResultReturned = false;
      this.registerForm.reset();
      this.description = '';
      this.lastImage = '';
      this.title = '';
      this.category = '';

      this.notificationService.showSuccess('Tip created successfully');

      if (!data.UserTip.IsRewardCreated) {
        // this.toastService.showSuccessToast(
        //   'Thank you! Tip added successfully.'
        // );
      } else {
        // const popover = await this.popoverController.create({
        //   component: MyrewardsComponent,
        //   cssClass: 'my-popover-custom-class',
        // });
        // await popover.present();
      }
      //      const { role } = await popover.onDidDismiss();
      //console.log('onDidDismiss resolved with role', role);
    });
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  selectFiles(event) {
    this.lastImage = null;
    this.videoUrl = null;
    if (event.target.files) {
      //this.spinner.show();
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append('file', this.fileData);
      var showImageOrVideoUrl = '';
      var isImage = this.showImageOrVideo();

      if (isImage) {
        showImageOrVideoUrl =
          'https://swashmarket.azurewebsites.net/v1/ImageController/PostUploadImage';
      } else {
        showImageOrVideoUrl =
          'https://swashmarket.azurewebsites.net/v1/ImageController/PostUserVideo';
      }

      this.http
        .post(showImageOrVideoUrl, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              var progress1 = (event.loaded / event.total) * 100;
              console.log(`Uploaded! ${progress1}%`);
              if (progress1 != 100) {
                this.progress = progress1;
              }
              break;
            case HttpEventType.Response:
              this.fileUploaded(event, isImage);
              setTimeout(() => {
                this.progress = 0;
              }, 1500);
          }
        });
    }
  }

  fileUploaded(res, isImage) {
    console.log(res.body);
    var mediaUploadStatus = res.body as any;
    console.log(mediaUploadStatus);

    if (isImage) {
      this.lastImage =
        'https://swashstorage.blob.core.windows.net/products/' +
        mediaUploadStatus.MediaUploadStatus.FileName;
    } else {
      this.videoUrl =
        'https://swashstorage.blob.core.windows.net/youitipvideos/' +
        mediaUploadStatus.MediaUploadStatus.FileName;
      this.videoPosterUrl =
        'https://swashstorage.blob.core.windows.net/products/' +
        mediaUploadStatus.MediaUploadStatus.PosterImageName;
    }
    this.progress = 100;
  }

  showImageOrVideo() {
    if (
      this.fileData.name.includes('JPEG') ||
      this.fileData.name.includes('jpeg') ||
      this.fileData.name.includes('png') ||
      this.fileData.name.includes('jpg') ||
      this.fileData.name.includes('PNG') ||
      this.fileData.name.includes('JFIF') ||
      this.fileData.name.includes('jfif') ||
      this.fileData.name.includes('TIFF') ||
      this.fileData.name.includes('tiff') ||
      this.fileData.name.includes('TIF') ||
      this.fileData.name.includes('tif') ||
      this.fileData.name.includes('JPG')
    ) {
      return true;
      // this.lastImage =
      //   'https://swashstorage.blob.core.windows.net/products/' +
      //   mediaUploadStatus.MediaUploadStatus.FileName;
    } else {
      // this.videoUrl =
      //   'https://swashstorage.blob.core.windows.net/products/' +
      //   mediaUploadStatus.MediaUploadStatus.FileName;

      return false;
    }
  }


  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
