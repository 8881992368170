<div class="blog-wrapper">
<table class="blog-table">
  <thead>
    <tr class="head-row">
      <th *ngFor="let columnName of columnNames; let i = index">
        <h2>{{ columnName }}</h2>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of dataSource" class="body-row">
      <td *ngIf="item.ColumnValue1">{{ item.ColumnValue1 }}</td>
      <td *ngIf="item.ColumnValue2">{{ item.ColumnValue2 }}</td>
      <td *ngIf="item.ColumnValue3">{{ item.ColumnValue3 }}</td>
      <td *ngIf="item.ColumnValue4">{{ item.ColumnValue4 }}</td>
      <td *ngIf="item.ColumnValue5">{{ item.ColumnValue5 }}</td>
      <td *ngIf="item.ColumnValue6">{{ item.ColumnValue6 }}</td>
      <td *ngIf="item.ColumnValue7">{{ item.ColumnValue7 }}</td>
      <td *ngIf="item.ColumnValue8">{{ item.ColumnValue8 }}</td>
      <!-- Add more cells as needed -->
    </tr>
  </tbody>
</table>
</div>
