<div class="common-banner mobileonly">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
      <h2>Membership Plans that helps you to grow parallelly</h2>
    </div> -->
</div>
<section class="common-overlap">
  <div class="common-text">
    <h2>Ai Comparison Feedback</h2>
  </div>
</section>
<section>
  <div class="container">
    <div class="common-text text-center">
      <!-- <h2 class="blk">Please share your views / suggestions / valueable feedback to improve the
        platform</h2> -->
      <p
        class="blk"
        style="font-size: 20px !important; font-weight: bold !important"
      >
        "Please share your views / suggestions / valueable feedback to improve
        the platform”
      </p>
    </div>
    <div class="Login">
      <div class="row">
        <!-- Frequency of use -->
        <div class="col-sm-12 p-0">
          <div class="form-group">
            <mat-label for="frequencyOfUse"
              >How often do you use our product?</mat-label
            >
            <mat-select
              [(ngModel)]="surveyData.frequencyOfUse"
              id="frequencyOfUse"
              name="frequencyOfUse"
            >
              <mat-option disabled value="">Select an option</mat-option>
              <mat-option
                *ngFor="let option of frequencyOptions"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <!-- Duration of use -->
        <div class="col-sm-12 p-0">
          <div class="form-group">
            <mat-label for="durationOfUse"
              >How long have you been using our product?</mat-label
            >

            <mat-select
              [(ngModel)]="surveyData.durationOfUse"
              id="durationOfUse"
              name="durationOfUse"
            >
              <mat-option disabled value="">Select an option</mat-option>
              <mat-option
                *ngFor="let option of durationOptions"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <!-- Satisfaction level -->
        <div class="col-sm-12 p-0">
          <div class="form-group">
            <mat-label for="satisfactionLevel"
              >On a scale of 1 to 10, how satisfied are you with our
              product?</mat-label
            >

            <mat-select
              [(ngModel)]="surveyData.satisfactionLevel"
              id="satisfactionLevel"
              name="satisfactionLevel"
            >
              <mat-option disabled value="">Select an option</mat-option>
              <mat-option
                *ngFor="let option of satisfactionOptions"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- Liked features -->
        <div class="col-sm-12 p-0">
          <div class="form-group">
            <mat-label for="likedFeatures"
              >What specific features do you like the most about our
              product?</mat-label
            >

            <mat-select
              [(ngModel)]="surveyData.likedFeatures"
              id="likedFeatures"
              name="likedFeatures"
            >
              <mat-option disabled value="">Select an option</mat-option>
              <mat-option
                *ngFor="let option of likedFeaturesOptions"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- Improvements -->
        <div class="col-sm-12 p-0">
          <div class="form-group">
            <div>
              <mat-label for="improvements"
                >What improvements would you like to see in our
                product?</mat-label
              >

              <mat-select
                [(ngModel)]="surveyData.improvements"
                id="improvements"
                name="improvements"
              >
                <mat-option disabled value="">Select an option</mat-option>
                <mat-option
                  *ngFor="let option of improvementsOptions"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <!-- Submit button -->
          <button (click)="executeAction('submitfeedback')">Submit</button>
        </div>
      </div>
    </div>
  </div>
</section>
