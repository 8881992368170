import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';

@Component({
  selector: 'app-categoryplatforms',
  templateUrl: './categoryplatforms.component.html',
  styleUrls: ['./categoryplatforms.component.scss'],
})
export class CategoryplatformsComponent {
  tips: any = [];
  loadMore: any;
  noResultReturned: any;
  items: any = [];
  userData: any;
  userEmail: any;
  categories: any = [];
  categoryId: any;
  tipliked: boolean;
  pageSize: any;
  searchinput: string;
  pageIndex: number;
  noMoreRecordsAvailable: any;
  platform1: string;
  platform2: string;
  platform3: string;
  platform4: string;

  itemsToCompare: string;

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<CategoryplatformsComponent>,
    private notificationService: NotificationService
  ) {
    this.itemsToCompare = '';
    this.tips = JSON.parse(localStorage.getItem('categoryplatformsComponent'));
    console.log(this.tips);
  }
  onRightClick($event) {}
  onTabChanged($event) {}
  close() {
    this.dialogRef.close();
  }

  viewTip(tip) {
    if (tip.Platform_Url == null) return;
    console.log(tip);
    const target = '_self';
    const refLink = window.open(tip.Platform_Url);
  }

  AddToCompare(tip) {
    console.log(tip);
    // console.log(this.itemsToCompare);
    // console.log(this.itemsToCompare.length);

    if (this.items.length == 4) {
      this.notificationService.showError('We can only compare 4 platforms');
      return;
    }

    if (this.itemsToCompare.length > 1) {
      var item = this.itemsToCompare.includes(tip.RowKey);
      if (item) return;
    }

    if (this.itemsToCompare.length > 1) {
      this.itemsToCompare = this.itemsToCompare + tip.RowKey + ';';
    } else {
      this.itemsToCompare = tip.RowKey + ';';
      this.items.push(this.itemsToCompare);
    }

    if (this.itemsToCompare.length > 0)
      this.items = this.itemsToCompare.split(';');

    this.items.pop('');

    console.log(this.items);

    if (this.items.length == 1) {
      this.platform1 = tip.Platform_Name;
    }
    if (this.items.length == 2) {
      this.platform2 = tip.Platform_Name;
    }
    if (this.items.length == 3) {
      this.platform3 = tip.Platform_Name;
    }
    if (this.items.length == 4) {
      this.platform4 = tip.Platform_Name;
    }

    console.log(this.items.length);
    console.log(this.itemsToCompare);
    console.log(
      this.itemsToCompare.substring(0, this.itemsToCompare.length - 1)
    );

    localStorage.setItem(
      'itemsToCompare',
      this.itemsToCompare.substring(0, this.itemsToCompare.length - 1)
    );
    this.dataService.notifyAboutAddToCompare(1);
  }
  comparePlatform() {
    this.dialogRef.close();
    this.dataService.notifyAboutCompareChange();

  }
}
