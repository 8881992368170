import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-whoisusingyoutip',
  templateUrl: './whoisusingyoutip.component.html',
  styleUrls: ['./whoisusingyoutip.component.scss'],
})
export class WhoisusingyoutipComponent implements OnInit {
  pageIndex: number;
  userList: any = [];
  userData: any;
  noResultReturned: boolean;
  followingText: string;
  AIComparisonLoggedInUserEmail: string;
  userEmail: any;
  myTipsCount: any;
  followerUserCount: any;
  followingUserCount: any;
  profilePicUrl: any;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    public dataService: DataService,
    private titleAndMetaService: TitleAndMetaService
  ) {}

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('Who is using YouITip Page');
    this.titleAndMetaService.setMetaTag();
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    if (this.userData != null) {
      console.log(this.userData);
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
    } else {
      this.userEmail = '';
    }

    this.pageIndex = 0;
    this.loadUsers('0');
  }

  otherUserEmail(otherUserEmail: any) {
    throw new Error('Method not implemented.');
  }

  loadUsers(pageIndex) {
    this.noResultReturned = true;
    this.dataService.notifyHideFooter('');
    console.log(this.userEmail);
    this.tipService
      .getUsers(this.userEmail, pageIndex)
      .subscribe(async (data) => {
        this.dataService.notifyShowFooter('');
        this.noResultReturned = false;
        console.log(data.Users);
        data.Users.forEach((element) => {
          console.log(element);
          this.userList.push({
            showFollow: false,
            followButtonText: element.IsFollowing ? 'Following' : 'Follow',
            picUrl: element.ProfilePic,
            Title: element.Name,
            UniqueCode: element.FirstName,
            customerEmail: element.UserEmail,
            UniqueCodeWithoutDot: element.FirstName,
            UserName: element.UserName,
            TipCount: element.TipCount,
            FollowerCount: element.FollowerCount,
            FollowingCount: element.FollowingCount,
          });
          this.profilePicUrl = element.ProfilePic;
          console.log(this.userList);
        });
      });
  }

  onScrollDown() {
    //this.spinner.show();
    console.log('scrolled');
    //this.noResultReturned = true;
    this.delay(2000).then(() => {
      console.log('ondemand');
      this.delayedServiceCall();
    });
  }
  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem('youtiphomepageIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('youtiphomepageIndex', pageIndex);
    console.log(pageIndex);
    this.loadUsers(pageIndex);
  }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      console.log(this.userData);
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        this.router.navigate(['/myprofile/editprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/otheruserprofile']);
      }
    }
  }

  followUser(selectedUser) {
    console.log(this.userEmail);

    console.log(selectedUser);

    console.log(selectedUser.customerEmail);

    let followModel = {
      PartitionKey: selectedUser.customerEmail,
      RowKey: this.userEmail,
    };

    console.log(followModel);

    this.tipService.followUser(followModel).subscribe(async (data) => {
      this.noResultReturned = false;
      console.log(data.Result);
      if (data.Result == 'Following') {
        selectedUser.followButtonText = 'Following';
        selectedUser.FollowerCount++;
      } else {
        selectedUser.followButtonText = 'Follow';
        if (selectedUser.FollowerCount > 0) {
          selectedUser.FollowerCount--;
        }
      }
    });
  }
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
