import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { CommentComponent } from '../comment/comment.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-viewtip',
  templateUrl: './viewtip.component.html',
  styleUrls: ['./viewtip.component.scss'],
})
export class ViewtipComponent implements OnInit {
  tipDetails: any;
  title: any;
  tips: any;
  userData: any;
  userEmail: any;
  notificationTipId: string;
  showMenu: boolean;
  noResultReturned: boolean;
  categoryId: any;
  selectedTip: any;
  currentIndex: any;
  showPrevious: boolean;
  showNext: boolean;
  isplaying: boolean;
  showplaybutton: boolean;
  mute: boolean;
  tipCreateDate: any;
  description: any;
  url: any;
  profilePic: any;
  userName: any;
  categoryName: any;
  tipliked: boolean;
  uInitials: string;

  constructor(
    private tipService: TipService,
    private router: Router,
    private titleAndMetaService: TitleAndMetaService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private notificationService: NotificationService,
    private _cdf: ChangeDetectorRef,
    private tipHelperService: TipHelperService,
    private activateRouter: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.currentIndex = 0;
    this.showPrevious = true;
    this.showNext = false;

    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);

    if (this.userData != null) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
    } else {
      this.userEmail = '';
    }
    this.titleAndMetaService.setTitle('View Tip Page');
    this.titleAndMetaService.setMetaTag();

    this.activateRouter.paramMap.subscribe((params) => {
      console.log(params);
      var tipId = params.get('id');
      console.log(tipId);
      if (tipId == null) {
        this.tipDetails = this.selectedTip = JSON.parse(
          localStorage.getItem('selectedTip')
        );
        console.log(this.tipDetails);
        var rowKey = this.tipHelperService.getRowKey(this.tipDetails);
        this.getTipDetails(rowKey);
        this.getTipViewCount();
      } else {
        this.getTipDetails(tipId);
      }
    });
  }

  getTipDetails(tipId) {
    this.noResultReturned = true;
    this.dataService.notifyHideFooter('');
    this.tipService
      .getTipByTipId(tipId, this.userEmail)
      .subscribe(async (data) => {
        this.noResultReturned = false;
        this.dataService.notifyShowFooter('');
        console.log(data);
        this.tipDetails = data.Tip;
        this._cdf.detectChanges();
        this.setTipData();
        this.tips = [];
        this.tips.push(this.tipDetails);
        console.log(this.tipDetails);
        this.loadTips('0');

        if (this.userData != null) {
          if (
            this.tipDetails.UserEmail == this.userData.LoggedInUser.UserEmail
          ) {
            this.showMenu = true;
          } else {
            this.showMenu = false;
          }
        }
      });
  }

  setTipData() {
    this.title = this.tipDetails.Title;
    this.tipCreateDate = this.tipDetails.Timestamp;
    this.categoryId = this.tipDetails.CategoryId;
    this.description = this.tipDetails.Description;
    this.url = this.tipDetails.Url;
    this.profilePic = this.tipDetails.ProfilePic;
    this.userName = this.tipDetails.UserName;
    this.categoryName = this.tipDetails.CategoryName;
    this.uInitials = this.tipDetails.UserName.slice(0, 2).toUpperCase();
  }

  slideUp() {}
  slideDown() {}

  tipByCategory() {
    console.log('tipbycategory', this.tipDetails);

    localStorage.setItem(
      'tipCategory',
      JSON.stringify({
        categoryName: this.tipDetails.CategoryName,
        categoryId: this.tipDetails.CategoryId,
      })
    );
    this.router.navigate(['/tipbycategory']);
  }

  loadTips(pageIndex) {
    this.noResultReturned = true;
    this.tipService
      .getCategoryTips(pageIndex, this.categoryId, this.userEmail)
      .subscribe(async (data) => {
        this.noResultReturned = false;
        console.log(data.CategoryTips);
        console.log(data.CategoryTips.length);

        if (data.CategoryTips.length > 1) {
          this.showNext = false;
        } else {
          this.showNext = true;
        }

        data.CategoryTips.forEach((element) => {
          var result = this.tips.filter((t) => t.RowKey == element.RowKey)[0];
          if (result == null && result == undefined) this.tips.push(element);
        });
      });
  }

  previousTip() {
    this.currentIndex--;
    if (this.currentIndex > 0) {
      this.tipDetails = this.tips[this.currentIndex];
      this.showNext = false;
    } else {
      this.currentIndex = 0;
      this.tipDetails = this.tips[0];
      this.showPrevious = true;
    }
    console.log(this.currentIndex);
    this.title = this.tipDetails.Title;
  }

  nextTip() {
    console.log(this.currentIndex);
    console.log(this.tips.length);
    this.currentIndex++;
    if (this.currentIndex < this.tips.length) {
      this.tipDetails = this.tips[this.currentIndex];
    } else {
      this.currentIndex = this.tips.length;
      this.showNext = true;
    }
    this.showPrevious = false;
    this.title = this.tipDetails.Title;
  }

  tipComment() {
    if (this.userData != null || this.userData != undefined) {
      localStorage.setItem('tipComment', JSON.stringify(this.tipDetails));
      const dialogRef = this.dialog.open(CommentComponent);
    } else {
      this.router.navigate(['/login']);
    }
  }

  // viewUserProfile(userEmail) {
  //   if (this.userData == null) {
  //     console.log(this.userData);
  //     this.router.navigate(['/login']);
  //   } else {
  //     if (userEmail == this.userEmail) {
  //       this.router.navigate(['/userprofile']);
  //     } else {
  //       localStorage.setItem('otherUserEmail', userEmail);
  //       this.router.navigate(['/otheruserprofile']);
  //     }
  //   }
  // }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        console.log('myprofile');
        this.router.navigate(['myprofile/editprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        console.log('otheruserprofile');
        this.router.navigate(['otheruserprofile/otherusertips']);
      }
    }
  }

  editTip(tip) {
    localStorage.removeItem('editTip');
    localStorage.setItem('editTip', tip);
    this.router.navigate(['/edittip']);
  }

  getTipViewCount() {
    console.log(this.tipDetails.RowKey);
    this.tipService
      .getTipViewCount(this.tipDetails.RowKey, this.userEmail)
      .subscribe(async (data) => {
        this.tipDetails.ViewCount = data.TotalTipsViewCount;
        console.log(data);
        console.log(this.tipDetails.ViewCount);
        this.dataService.notifyAboutViewsCount(
          this.tipDetails.RowKey,
          data.TotalTipsViewCount
        );
        this.cdr.detectChanges();
      });
  }

  vidEnded(event) {
    console.log(event);
    event.target.play();
  }

  videoClick() {
    console.log('video clicked');
    var vid = document.getElementById('myVideo') as any;
    console.log(vid);
    vid.play();
    this.isplaying = false;
    this.showplaybutton = false;
  }

  videoDivClick() {
    console.log('video clicked');
    var vid = document.getElementById('myVideo') as any;
    if (!this.showplaybutton) {
      vid.pause();
      this.isplaying = true;
      this.showplaybutton = true;
    } else {
      vid.play();
      this.isplaying = false;
      this.showplaybutton = false;
    }
  }

  pauseVideo() {
    console.log('pauseVideo');
    var vid = document.getElementById('myVideo') as any;
    vid.pause();
    this.isplaying = true;
    this.showplaybutton = true;
  }

  playVid() {
    console.log('playVid');
    var vid = document.getElementById('myVideo') as any;
    vid.play();
    this.isplaying = false;
    this.showplaybutton = false;
  }

  vidplay(event) {
    event.target.play();
  }

  muteUnmuteVideo(event) {
    var vid = document.getElementById('myVideo') as any;

    if (!vid.muted) {
      vid.muted = true;
      this.mute = true;
    } else {
      vid.muted = false;
      this.mute = false;
    }
  }

  async likeTip() {
    if (this.userData != null) {
      var rowKey = this.tipHelperService.getRowKey(this.tipDetails);
      console.log(rowKey);
      this.tipService
        .likeTip(
          rowKey,
          this.userData.LoggedInUser.UserEmail,
          this.tipDetails.LikeCount
        )
        .subscribe(async (data) => {
          console.log(data.IsTipLiked);
          if (data.IsTipLiked) {
            this.notificationService.showAnimation(this.tips, this.tipDetails);
            this.tipDetails.LikeCount++;
            this.tipliked = true;
            this.tipDetails.IsTipLiked = true;
            setTimeout(() => {
              this.tipliked = false;
            }, 2000);
          } else {
            this.tipDetails.LikeCount--;
            this.tipDetails.IsTipLiked = false;
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveTip() {
    console.log(this.tipDetails);
    if (this.userData != null) {
      if (this.userData != null) {
        var rowKey = this.tipHelperService.getRowKey(this.tipDetails);
        this.tipService
          .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
          .subscribe(async (data) => {
            console.log(data);
            this.tipDetails.IsTipSaved = data.IsTipSaved;
            if (data.IsTipSaved) {
              this.notificationService.showAnimation(this.tips, this.tipDetails);
              this.tipDetails.SaveCount++;
              this.tipliked = true;
              setTimeout(() => {
                this.tipliked = false;
              }, 2000);
            } else {
              this.tipDetails.SaveCount--;
            }
          });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  viewMobileUserProfile(userEmail) {
    console.log('inviewmobileuser');
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        this.router.navigate(['/muserprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/mothertips']);
      }
    }
  }
  

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
