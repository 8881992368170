import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TipService } from 'src/app/sharedService/tip.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  userData: any;
  pricingPlans: any = [];

  constructor(private router: Router, private tipService: TipService) {
    var loggedInUser = localStorage.getItem('AIComparisonLoggedInUser');
    if (loggedInUser != null && loggedInUser != undefined) {
      this.userData = JSON.parse(loggedInUser);
      console.log(this.userData);
    }
  }

  ngOnInit(): void {
    this.getPricingPlans();
  }

  async getPricingPlans() {
    this.tipService.getPricingPlans().subscribe(async (data) => {
      this.pricingPlans = data.PricingPlans;
    });
  }

  goToCreateTip() {
    this.router.navigate(['/createtip']);
  }

  gotofaq() {
    this.router.navigate(['/faq']);
  }

  goToCheckout(planId) {
    this.userData = JSON.parse(localStorage.getItem('AIComparisonLoggedInUser'));
    console.log(this.userData);

    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      var plan = {
        planId: planId,
        Price: planId == 2 ? 200 : 400,
      };

      localStorage.setItem('checkoutplan', JSON.stringify(plan));
      this.router.navigate(['/checkout']);
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  async goToPlan(pricingPlan) {
    if (pricingPlan.PlanName != 'Free') {
      this.generateOrderId(pricingPlan);
    } else {
      this.router.navigate(['/createtip']);
    }
  }

  async generateOrderId(plan) {
    var loggedInUser = localStorage.getItem('AIComparisonLoggedInUser');
    if (loggedInUser == null) {
      this.router.navigate(['/login']);
    } else {
      localStorage.setItem('pricingPlan', plan);
      this.router.navigate(['/checkout']);
    }
  }
}
