import { ReportComponent } from './featureModule/report/report.component';
import { FilterComponent } from './featureModule/filter/filter.component';
import { ScratchcardComponent } from './featureModule/scratchcard/scratchcard.component';
import { MyrewardsComponent } from './featureModule/myrewards/myrewards.component';
import { CreatetipComponent } from './featureModule/createtip/createtip.component';
import { EditcommentComponent } from './featureModule/editcomment/editcomment.component';
import { CommentComponent } from './featureModule/comment/comment.component';
import { EditprofileComponent } from './featureModule/editprofile/editprofile.component';
import { OtheruserprofileComponent } from './featureModule/otheruserprofile/otheruserprofile.component';
import { WhoisusingyoutipComponent } from './featureModule/whoisusingyoutip/whoisusingyoutip.component';
import { ReelsComponent } from './featureModule/reels/reels.component';
import { NotificationsComponent } from './featureModule/notifications/notifications.component';
import { ChangepasswordComponent } from './featureModule/changepassword/changepassword.component';
import { SavedtipsComponent } from './featureModule/savedtips/savedtips.component';
import { UserprofileComponent } from './featureModule/userprofile/userprofile.component';
import { TipbycategoryComponent } from './featureModule/tipbycategory/tipbycategory.component';
import { SearchComponent } from './featureModule/search/search.component';
import { ViewtipComponent } from './featureModule/viewtip/viewtip.component';
import { ThirdpartylibraryComponent } from './featureModule/thirdpartylibrary/thirdpartylibrary.component';
import { ActivateaccountComponent } from './featureModule/activateaccount/activateaccount.component';
import { RegisterComponent } from './featureModule/register/register.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './featureModule/home/home.component';
import { LoginComponent } from './featureModule/login/login.component';
import { PpolicyComponent } from './featureModule/ppolicy/ppolicy.component';
import { TandcComponent } from './featureModule/tandc/tandc.component';
import { AboutusComponent } from './sharedModule/aboutus/aboutus.component';
import { ContactusComponent } from './sharedModule/contactus/contactus.component';
import { CategoriesComponent } from './featureModule/categories/categories.component';
import { PricingComponent } from './sharedModule/pricing/pricing.component';
import { MyprofileComponent } from './featureModule/myprofile/myprofile.component';
import { OneminvideosComponent } from './sharedModule/oneminvideos/oneminvideos.component';
import { FollowersComponent } from './featureModule/followers/followers.component';
import { FollowingComponent } from './featureModule/following/following.component';
import { OtherusertipsComponent } from './featureModule/otherusertips/otherusertips.component';
import { MFollowersComponent } from './featureModule/m-followers/m-followers.component';
import { MFollowingComponent } from './featureModule/m-following/m-following.component';
import { MChangedPasswordComponent } from './featureModule/m-changed-password/m-changed-password.component';
import { MSavedTipsComponent } from './featureModule/m-saved-tips/m-saved-tips.component';
import { MyTipsComponent } from './featureModule/my-tips/my-tips.component';
import { MOtherTipsComponent } from './featureModule/m-other-tips/m-other-tips.component';
import { MEditProfileComponent } from './featureModule/m-edit-profile/m-edit-profile.component';
import { FaqComponent } from './sharedModule/faq/faq.component';
import { PagenotfoundComponent } from './sharedModule/pagenotfound/pagenotfound.component';
import { CheckoutComponent } from './featureModule/checkout/checkout.component';
import { MrewardComponent } from './featureModule/mreward/mreward.component';
import { RefundpolicyComponent } from './sharedModule/refundpolicy/refundpolicy.component';
import { McategoriesComponent } from './featureModule/mcategories/mcategories.component';
import { MnotificationsComponent } from './featureModule/mnotifications/mnotifications.component';
import { FollowerstipsComponent } from './featureModule/followerstips/followerstips.component';
import { MfollowerstipsComponent } from './featureModule/mfollowerstips/mfollowerstips.component';
import { PaymentsuccessfulComponent } from './sharedModule/paymentsuccessful/paymentsuccessful.component';
import { PaymentfailComponent } from './sharedModule/paymentfail/paymentfail.component';
import { MyplanComponent } from './featureModule/myplan/myplan.component';
import { MmyplanComponent } from './featureModule/mmyplan/mmyplan.component';
import { CompareaiComponent } from './featureModule/compareai/compareai.component';
import { BlogComponent } from './featureModule/blog/blog.component';
import { BloglistComponent } from './featureModule/bloglist/bloglist.component';
import { FeedbackComponent } from './featureModule/feedback/feedback.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'tandc', component: TandcComponent },
  { path: 'ppolicy', component: PpolicyComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'activateaccount', component: ActivateaccountComponent },
  { path: 'thirdparty', component: ThirdpartylibraryComponent },
  { path: 'viewtip', component: ViewtipComponent },
  { path: 'viewtip/:id', component: ViewtipComponent },
  { path: 'search', component: SearchComponent },
  // { path: 'savedtips', component: SavedtipsComponent },
  { path: 'mnotifications', component: MnotificationsComponent },
  // { path: 'changepassword', component: ChangepasswordComponent },
  { path: 'reels', component: ReelsComponent },
  { path: 'tipbycategory', component: TipbycategoryComponent },
  { path: 'tipbycategory/:id', component: TipbycategoryComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'categories', component: CategoriesComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'paymentsuccess', component: PaymentsuccessfulComponent },
  { path: 'paymentfail', component: PaymentfailComponent },
  { path: 'whoisusingyouitip', component: WhoisusingyoutipComponent },
  { path: 'comment', component: CommentComponent },
  { path: 'editcomment', component: EditcommentComponent },
  { path: 'createtip', component: CreatetipComponent },
  { path: 'scratchcard', component: ScratchcardComponent },
  { path: 'filter', component: FilterComponent },
  { path: 'report', component: ReportComponent },
  { path: 'muserprofile', component: MyTipsComponent },
  { path: 'msavedtips', component: MSavedTipsComponent },
  { path: 'mchangepassword', component: MChangedPasswordComponent },
  { path: 'rewards', component: MrewardComponent },
  { path: 'meditprofile', component: MEditProfileComponent },
  { path: 'oneminvids', component: OneminvideosComponent },
  { path: 'mfollowers', component: MFollowersComponent },
  { path: 'mcategories', component: McategoriesComponent },
  { path: 'mfollowing', component: MFollowingComponent },
  { path: 'mothertips', component: MOtherTipsComponent },
  { path: 'mmyplan', component: MmyplanComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'refundpolicy', component: RefundpolicyComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'mfollowerstips', component: MfollowerstipsComponent },
  { path: 'compareai', component: CompareaiComponent },
  { path: 'blogs', component: BloglistComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'blog/:title', component: BlogComponent },
  {
    path: 'myprofile',
    component: MyprofileComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'editprofile' },
      { path: 'editprofile', component: EditprofileComponent },
      { path: 'userprofile', component: UserprofileComponent },
      { path: 'savedtips', component: SavedtipsComponent },
      { path: 'followerstips', component: FollowerstipsComponent },
      { path: 'changepassword', component: ChangepasswordComponent },
      { path: 'rewards', component: MyrewardsComponent },
      { path: 'editprofile', component: EditprofileComponent },
      { path: 'followers', component: FollowersComponent },
      { path: 'following', component: FollowingComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'myplan', component: MyplanComponent },
    ],
  },
  {
    path: 'otheruserprofile',
    component: OtheruserprofileComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'otherusertips' },
      { path: 'otherusertips', component: OtherusertipsComponent },
      { path: 'followerTips', component: FollowerstipsComponent },
    ],
  },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
