import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';

@Component({
  selector: 'app-bloglist',
  templateUrl: './bloglist.component.html',
  styleUrls: ['./bloglist.component.scss'],
})
export class BloglistComponent implements OnInit {
  blogs: any = [];
  noResultReturned: boolean;
  loadMore: boolean;
  pageSize: any;
  coverImageUrl: any;
  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService,
    private _cdf: ChangeDetectorRef,
    private tipHelperService: TipHelperService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.pageSize = 12;
    this.noResultReturned = false;
    this.loadMore = false;
  }

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('blogs');
    console.log('changes for blog list');
    this.getBlogs('0');
  }

  getBlogs(pageIndex) {
    this.noResultReturned = true;
    this.tipService.getBlogs(pageIndex).subscribe((res) => {
      this.noResultReturned = false;

      res.BlogModels.forEach((element) => {
        if (element.CoverImageUrl == null)
          element.CoverImageUrl =
            'url(../../../../assets/img/default_blog_images.png)';
        else
          element.CoverImageUrl =
            'url(' + '"' + element.CoverImageUrl + '"' + ')';
        this.blogs.push(element);
      });

      this.setLoadMore(res.BlogModels);
      console.log(this.blogs);
      console.log(this.blogs);
      this.noResultReturned = false;
    });
  }

  setLoadMore(tips) {
    this.loadMore = !(tips.length < this.pageSize.Value);
  }

  onTabChanged(event) {}
  onRightClick(event) {}

  viewblog(blog) {
    localStorage.setItem('selectedBlog', JSON.stringify(blog));
    this.router.navigate(['/blog/' + blog.TitleWithDashes]);
  }
}
