import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  imageUrl: any;
  @Input() rowKey: string;

  constructor() {}
  ngOnInit(): void {
    console.log('changes for blog list');
    this.blog = JSON.parse(localStorage.getItem('selectedBlog'));
    console.log(
      this.blog.BlogImages.filter((b) => b.RowKey == this.rowKey)[0]
        ?.ImageUrl
    );
    this.imageUrl = this.blog.BlogImages.filter(
      (b) => b.RowKey == this.rowKey
    )[0]?.ImageUrl;
  }
  onRightClick($event) {}
  loadMoreblogs() {}
}

