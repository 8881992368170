<div class="whitebg-new" (contextmenu)="onRightClick($event)">
  <div (click)="close()" class="close-ico">
    <i style="cursor: pointer" class="fa fa-times"></i>
  </div>
  <div
    *ngIf="tips?.length > 0"
    class="scroll user-pro"
    class="card-handler"
    (scrolled)="onScrollDown()"
    class="container mobileoutoheight newdesigntile"
  >
    <div class="row homeTip">
      <div class="card-handler">
        <div class="card-wrap" *ngFor="let tip of tips">
          <div
            (click)="viewTip(tip)"
            style="cursor: pointer"
            class="image-wrap"
          >
            <img
              *ngIf="tip.ShowImage && tip.Logo_Url"
              (click)="viewTip(tip)"
              class="img-fluid"
              src="{{ tip.Logo_Url }}"
              alt="Card image cap"
              style="cursor: pointer"
            />

            <img
              *ngIf="!tip.ShowImage && tip.Logo_Url"
              (click)="viewTip(tip)"
              class="img-fluid"
              src="../../../../assets/img/logo-black-new.png"
              alt="Card image cap"
              style="cursor: pointer"
            />
            <h1
              style="cursor: pointer"
              (click)="viewTip(tip)"
              (click)="viewTip(tip)"
              *ngIf="!tip.Url"
            >
              {{ tip.Title | titlecase }}
            </h1>
            <div class="cat-name">
              <span
                style="height: 17px; margin-top: 6px; cursor: pointer"
                class="badge badge-primary"
              >
                #{{ tip.Cateogry }}
              </span>
            </div>
          </div>
          <div class="text-wrap">
            <h1 (click)="viewTip(tip)" (click)="viewTip(tip)" *ngIf="tip.Url">
              {{ tip.Title | titlecase }}
            </h1>
            <div class="user-group usergroupdesktop">
              <div class="days">
                <p>
                  <span class="txt"
                    ><i class="fas fa-clock" title="I like it"></i
                    >{{ tip.Timestamp | dateAgo }}
                  </span>
                </p>
              </div>
            </div>

            <p (click)="viewTip(tip)">{{ tip.Description }}</p>

            <div class="bottom-fix">
              <div class="button-wrap">
                <div class="cat-name">
                  <a (click)="AddToCompare(tip)" style="cursor: pointer"
                    >Add To Compare</a
                  >
                </div>
                <div class="days">
                  <p>
                    <span class="txt">
                      <i class="fas fa-clock" title="I like it"></i
                      >{{ tip.Timestamp | dateAgo }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="tip.AnimateTip" class="anisecondheart">
              <div class="bubbling-heart">
                <div><i class="fa fa-heart fa-5x"></i></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h5 class="Usermsg" *ngIf="!loadMore && tips?.length == 0">
    Selected category currently has no platforms to display
  </h5>
</div>
<div class="load-more">
  <button *ngIf="loadMore" (click)="loadMoreTips()">Load more</button>
  <h5 class="Usermsg" *ngIf="!loadMore">Thats it for now :)</h5>
  <div class="popupcompare">
    <button data-toggle="modal" (click)="comparePlatform()" data-target="#LoginAccount" class="btn btn-create-post"> Compare platforms</button>
  </div>
</div>
<div *ngIf="noResultReturned" class="img-div-left">
  <img
    alt="loading spinner"
    onContextMenu="return false;"
    class="spinner-global img-fluid"
    style="width: 100px; height: 100px; display: block; margin: 0px auto"
    src="../../../../assets/img/Spinner.gif"
  />
</div>
